import makeStyles from './../makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DateField, TextField, Datagrid, ReferenceManyField, NumberField, TabbedShowLayout, TabbedShowLayoutTabs, Tab, Pagination, ChipField } from 'react-admin'
import {Typography} from '@mui/material'
import FancyFlagField from '../results/fancyFlag'
import EventsResultsLink from '../../components/events/eventsResultsLink'
import ContactRankingsShowAll from '../../components/contacts/contactRankingsShowAll'
import ContactResultCard from '../../components/contacts/contactResultCard'
import SeCode from './seCode'
const useStyles = makeStyles({
	headerCell: {
		textAlign: 'left'
	},
	rowCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
	code: {
		padding: '16px',
		backgroundColor: '#eeeeee'
	}
})
export const EventsShow = () => {
	const classes = useStyles()
	const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
	const isMed = useMediaQuery(theme => theme.breakpoints.down('md'))
	const isLrg = useMediaQuery(theme => theme.breakpoints.down('lg'))
	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))
	return (
		<>
			<TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable"/>}>
				<Tab label="Details">
					<Typography>SportsEngine Code</Typography>
					<SeCode className={classes.code} />
					<TextField source="SubEventId" label="SubEventId" />
					<EventsResultsLink label="Results Link" />
					<ChipField source="Brand" label="Brand" />
					<TextField source="Series" label="Series" />
					<TextField source="Event" label="Event" />
					<TextField source="SubEvent" label="SubEvent" />
					<DateField source="Date" label="Date" />
					<TextField source="Latitude" label="Latitude" />
					<TextField source="Longitude" label="Longitude" />
					<TextField source="Alias" label="Alias" />
					<TextField source="StandardName" label="StandardName" />
					<TextField source="BusinessUnit" label="Region" />
					<TextField source="Timezone" label="Timezone" />
					<TextField source="City" label="City" />
					<TextField source="StateorProvince" label="StateorProvince" />
					<TextField source="Country" label="Country" />
					<TextField source="Sport" label="Sport" />
					<NumberField source="DistanceInMiles" label="Distance" />
					<NumberField source="DistanceInKM" label="Distance" />
				</Tab>
				<Tab label="Results" path="results">
					<ReferenceManyField reference="results" target="SubEventId" label=" " pagination={<Pagination />} sort={{ field: 'FinishRankOverall', order: 'ASC' }} perPage={5}>
						{isXs ? (
							<Datagrid expand={<ContactRankingsShowAll />} rowClick="expand" expandSingle bulkActionButtons={false} optimized>
								<TextField source="Subevent.SubEvent" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="Contact.FullName" label="Athete" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<FancyFlagField />
								<TextField source="FinishTime" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<NumberField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
							</Datagrid>
						) : isSmall ? (
							<Datagrid expand={<ContactRankingsShowAll />} rowClick="expand" expandSingle bulkActionButtons={false} optimized>
								<TextField source="Subevent.SubEvent" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="Contact.FullName" label="Athete" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<FancyFlagField />
								<TextField source="FinishTime" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							</Datagrid>
						) : isMed ? (
							<Datagrid expand={<ContactResultCard />} rowClick="expand" expandSingle bulkActionButtons={false} optimized>
								<TextField source="Subevent.SubEvent" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="Contact.FullName" label="Athete" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="AgeGroup" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<FancyFlagField />
								<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="SwimTime" label="Swim" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="BikeTime" label="Bike" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="RunTime" label="Run" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="FinishTime" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<NumberField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
							</Datagrid>
						) : isLrg ? (
							<Datagrid expand={<ContactResultCard />} classes={classes} rowClick="expand" expandSingle bulkActionButtons={false} optimized>
								<TextField source="Subevent.SubEvent" label="Event" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="Contact.FullName" label="Athete" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="AgeGroup" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<FancyFlagField />
								<TextField source="BibNumber" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
								<TextField source="EventStatus" />
								<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="SwimTime" label="Swim" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="BikeTime" label="Bike" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="RunTime" label="Run" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="FinishTime" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
							</Datagrid>
						) : (
							<Datagrid expand={<ContactResultCard />} classes={classes} rowClick="expand" bulkActionButtons={false} expandSingle optimized>
								<TextField source="Subevent.SubEvent" label="Event" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="Contact.FullName" label="Athete" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="AgeGroup" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<FancyFlagField />
								<TextField source="BibNumber" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="EventStatus" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="SwimTime" label="Swim" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="BikeTime" label="Bike" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="RunTime" label="Run" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<TextField source="FinishTime" label="Finish" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
								<NumberField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							</Datagrid>
						)
						}
					</ReferenceManyField>
				</Tab>
				<Tab label="Club points" path="clubpointsdetail">
					<ReferenceManyField reference="clubpointsdetail" target="SubEventId" label=" " pagination={<Pagination />} sort={{ field: 'Date', order: 'DESC' }} perPage={5}>
						<Datagrid expandSingle bulkActionButtons={false} optimized>
							<TextField source="Contact.FullName" label="Athlete" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="ClubPoint.Club.name" label="Club" linkType="show" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="Points" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					</ReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</>
	)
}
export default EventsShow