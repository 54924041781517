import makeStyles from './../makeStyles'
import { TextField, Datagrid, NumberField, ReferenceManyField, Pagination } from 'react-admin'
import ContactResultShow2 from './contactResultShow2'
const useStyles = makeStyles({
	center: {
		maxWidth: '33%',
		minWidth: '33%',
		flexBasis: '33%',
		width: '100%',
		textAlign: 'center !important;',
		'&:hover': {
			color: '#e31837'
		}
	}
})
const ClubPointsPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />
export const ContactRankingsShowAll2 = () => {
	const classes = useStyles()
	return (
		<ReferenceManyField label="Details" reference="clubpointsdetail/totals/grouped/grouped" target="clubpointsid" id="clubpointsid" perPage={1000} sort={{ field: 'Date', order: 'DESC' }} pagination={<ClubPointsPagination />}>
			<Datagrid expand={<ContactResultShow2 />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
				<TextField source="SubEventName" label="Race" cellClassName={classes.center} headerClassName={classes.center} sortable={false} />
				<NumberField source="Athletes" label="Athletes" cellClassName={classes.center} headerClassName={classes.center} sortable={false} />
				<NumberField source="Points" label="Points" cellClassName={classes.center} headerClassName={classes.center} sortable={false} />
			</Datagrid>
		</ReferenceManyField>
	)
}
export default ContactRankingsShowAll2