import makeStyles from './../makeStyles'
import {useMediaQuery} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { List, Loading, TextField, Datagrid, NumberField, Pagination, useGetOne } from 'react-admin'
import CardShowRun from './cardShowRun'
import FancyFlagField from './fancyFlag'
import ResultsFilter from './resultsFilter'
import ResultCountryISO from './resultCountryISO'
import ResultContactName from './resultContactName'
import ContactResultCard from '../../components/contacts/contactResultCard'
const ResultsPagination = props => <Pagination rowsPerPageOptions={[3, 5, 10, 15, 20, 25, 50]} {...props} />
const useStyles = makeStyles({
	headerCell: {
		textAlign: 'center !important'
	},
	flag: {
		paddingTop: '5px',
		padding: '6px',
		verticalAlign: 'middle',
		'& img': {
			verticalAlign: 'middle'
		}
	},
	rowCell: {
		textAlign: 'center !important',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
})
const NoneActions = (props) => (
	<p></p>
)
export const PublicResultsList = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const isXs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
	const isMed = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
	const isLrg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
	const isXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
	const subeventId = window.location.pathname.split('/').pop()
	const { data, isLoading, error } = useGetOne('events', { id: subeventId })
	if (isLoading) { return <Loading /> }
	if (error) { return <p>ERROR</p> }
	if(!data) return null
	if (ResultsFilter){
		if (data.SubEvent && data.SubEvent.includes('Rock')) {
			return (
				<List {...props} filters={<ResultsFilter />} perPage={10} title={data.SubEvent} pagination={<ResultsPagination />} bulkActionButtons={false} actions={<NoneActions />}
					exporter={false} sort={{ field: 'FinishRankOverall', order: 'ASC' }}>
					{isXs ? (
						<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isSmall ? (
						<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isMed ? (
						<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isLrg ? (
						<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="CountryISO2" label="Country" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isXL ? (
						<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="CountryISO2" label="Country" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					)
					}
				</List>
			)
		} else {
			return (
				<List {...props} filters={<ResultsFilter />} perPage={10} pagination={<ResultsPagination />} title={data.SubEvent} actions={<NoneActions />}
					exporter={false} sort={{ field: 'FinishRankOverall', order: 'ASC' }}>
					{isXs ? (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isSmall ? (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isMed ? (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isLrg ? (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<ResultCountryISO label="Country" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="SwimTimeConverted" label="Swim" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="BikeTimeConverted" label="Bike" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : isXL ? (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<ResultCountryISO label="Country" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="SwimTimeConverted" label="Swim" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="BikeTimeConverted" label="Bike" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<TextField source="RankPoints" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					) : (
						<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
							<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<FancyFlagField cellClassName={classes.flag} />
							<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
							<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						</Datagrid>
					)
					}
				</List>
			)
		}
	}
}
export default PublicResultsList