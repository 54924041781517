import { AppBar } from 'react-admin'
import makeStyles from './services/components/makeStyles'
import {Typography} from '@mui/material'
const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		padding: '15px'
	},
	appFrame: {
		marginTop: '0 !important;'
	}
})
export const MyAppBar = props => {
	const classes = useStyles()
	return (
		<AppBar userMenu={false} className={classes.appFrame} position="relative">
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>
		</AppBar>
	)
}
export default MyAppBar