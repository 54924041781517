import { useRecordContext } from 'react-admin'
import Flag from 'react-flags'
export const ClubsCountry = () => {
	const record = useRecordContext()
	return record && record.Countries && record.Countries.ISO2
		? (<Flag basePath="/img/flags"
			name={record.Countries.ISO2}
			format="svg"
			svgSize={32}
			shiny={false}
			alt={record.Countries.ISO2}
			title={record.Countries.ISO2}
			width={32}
		/>)
		: null
}
export default ClubsCountry