import restClient from './restClient'
import { addUploadFeature } from './services/components/addUploadFeature'
import feathersClient from './feathersClient'
import { theme } from './newTheme'
import { BrowserRouter } from 'react-router-dom'
import { Resource, Admin, memoryStore } from 'react-admin'
import ClubsList from './services/clubs'
import EventsList from './services/events'
import PublicResultsList from './services/components/results/publicResultList'
import NewLayout2 from './newLayout2'
import PublicResultsListOpenDiv from './services/components/results/publicResultListOpenDiv'
import RanksList from './services/ranks'
import { QueryClient } from 'react-query'
import ClubEditTabbed from './services/components/clubs/clubEditTabbed'
import ClubsShowNoTabs2 from './services/components/clubs/clubsShowNoTabs2'
import ClubPointsList2 from './services/components/clubpoints/clubPointsList2'
import ClubPointsList3 from './services/components/clubpoints/clubPointsList3'
import ClubPointsList4 from './services/components/clubpoints/clubPointsList4'
import ClubPointsDetailsList from './services/clubpointsdetail'
import EventsShow from './services/components/events/eventsShow'
import ClubMultiStepFormActual from './services/components/clubs/clubMultiStepFormActual'
import MyNotification from './myNotification'
const options = {
	usePatch: false,
	contacts: {
		id: 'ContactID'
	},
	events: {
		id: 'SubEventId'
	},
	rank: {
		id: 'RankId'
	},
	results: {
		id: 'ResultId'
	},
	'result/subevent': {
		id: 'ResultId'
	},
	'result/subevent/opendivision': {
		id: 'ResultId'
	},
	clubpoints: {
		id: 'ClubPointsId'
	},
	clubpointsdetail: {
		id: 'clubpointsDetailId'
	},
	'clubpointsdetail/subevent': {
		id: 'ClubPointsID'
	},
	'clubpointsdetail/podium/subevent': {
		id: 'clubpointsId'
	},
	'clubpointsdetail/totals/clubpointsid': {
		id: 'SubEvent'
	},
	'clubpointsdetail/clubpointsid': {
		id: 'ClubPointsID'
	},
	'clubpointsdetail/totals/grouped/grouped': {
		id: 'SubEvent'
	},
	clubmember: {
		id: 'ClubMemberId'
	},
	'clubpoints/year/2013': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2014': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2015': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2016': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2017': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2018': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2019': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2020': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2021': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2022': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2023': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2024': {
		id: 'ClubPointsId'
	},
	'clubpoints/year/2025': {
		id: 'ClubPointsId'
	},
	'clubs/sport/tri': {
		id: 'clubId'
	},
	'clubs/sport/swim': {
		id: 'clubId'
	},
	'clubs/sport/bike': {
		id: 'clubId'
	},
	'clubs/sport/run': {
		id: 'clubId'
	},
	'clubs/sport/all': {
		id: 'clubId'
	},
	'clubs/sport/all/complete/true': {
		id: 'clubId'
	},
	'clubs/sport/all/complete/false': {
		id: 'clubId'
	},
	'clubs/sport/tri/complete/true': {
		id: 'clubId'
	},
	'clubs/sport/tri/complete/false': {
		id: 'clubId'
	},
	'clubs/sport/run/complete/true': {
		id: 'clubId'
	},
	'clubs/sport/run/complete/false': {
		id: 'clubId'
	},
	'clubs/sport/bike/complete/true': {
		id: 'clubId'
	},
	'clubs/sport/bike/complete/false': {
		id: 'clubId'
	},
	'clubs/sport/swim/complete/true': {
		id: 'clubId'
	},
	'clubs/sport/swim/complete/false': {
		id: 'clubId'
	},
	clubs: {
		id: 'clubId'
	},
	club: {
		id: 'clubId'
	},
	'rank/seasonname/2024-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2024-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2023-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2023-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2023-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2022-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2022-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2022-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2021-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2021-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2021-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2019-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2019-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2019-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2020-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2020-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2020-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2018-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2018-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2018-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2017-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2017-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2017-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2016-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2016-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2016-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2015-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2015-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2015-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2014-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2014-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2014-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2013-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2013-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2013-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2012-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2012-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2012-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2011-ironman': {
		id: 'RankId'
	},
	'rank/seasonname/2011-overall': {
		id: 'RankId'
	},
	'rank/seasonname/2011-ironman-703': {
		id: 'RankId'
	},
	'rank/seasonname/2015-5i50-and-international': {
		id: 'RankId'
	},
	'rank/seasonname/2015-sprint-triathlon"': {
		id: 'RankId'
	}
}
const App = () => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 60 * 5000, // 5 minute
			},
		},
	})
	return (
		<BrowserRouter>
			<Admin
				disableTelemetry
				dataProvider={addUploadFeature(restClient(feathersClient, options))}
				title=" "
				queryClient={queryClient}
				notification={MyNotification}
				store={memoryStore()}
				layout={NewLayout2}
				theme={theme}
			>
				<Resource name="clubs" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubpointsdetail/subevent" list={ClubPointsList3} />
				<Resource name="clubpointsdetail/podium/subevent" list={ClubPointsList4} />
				<Resource name="clubpointsdetail" list={ClubPointsDetailsList} />
				<Resource name="clubpointsdetail/totals/clubpointsid" list={ClubPointsDetailsList} />
				<Resource name="clubpointsdetail/totals/grouped/grouped" list={ClubPointsList2} />
				<Resource name="events" list={EventsList} show={EventsShow} />
				<Resource name="clubpoints/year/2013" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2014" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2015" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2016" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2017" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2018" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2019" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2020" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2021" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2022" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2023" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2024" list={ClubPointsList2} />
				<Resource name="clubpoints/year/2025" list={ClubPointsList2} />
				<Resource name="result/subevent/opendivision" list={PublicResultsListOpenDiv} label=" " />
				<Resource name="result/subevent" list={PublicResultsList} label=" "/>
				<Resource name="results" list={PublicResultsList} label=" " />
				<Resource name="clubpoints" list={ClubPointsList2} />
				<Resource name="clubs/sport/tri/complete/true" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/tri/complete/false" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/run/complete/true" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/run/complete/false" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/bike/complete/true" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/bike/complete/false" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/swim/complete/true" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/swim/complete/false" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/all/complete/true" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/all/complete/false" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/run" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/swim" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/bike" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/tri" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="clubs/sport/all" list={ClubsList} edit={ClubEditTabbed} show={ClubsShowNoTabs2} />
				<Resource name="club" create={ClubMultiStepFormActual} />
				<Resource name="rank/seasonname/2026-ironman" list={RanksList} options={{ label: '2026 IRONMAN' }} />
				<Resource name="rank/seasonname/2026-overall" list={RanksList} options={{ label: '2026 Overall' }} />
				<Resource name="rank/seasonname/2026-ironman-703" list={RanksList} options={{ label: '2026 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2025-ironman" list={RanksList} options={{ label: '2025 IRONMAN' }} />
				<Resource name="rank/seasonname/2025-overall" list={RanksList} options={{ label: '2025 Overall' }} />
				<Resource name="rank/seasonname/2025-ironman-703" list={RanksList} options={{ label: '2025 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2024-ironman" list={RanksList} options={{ label: '2024 IRONMAN' }} />
				<Resource name="rank/seasonname/2024-overall" list={RanksList} options={{ label: '2024 Overall (Earning towards 2025 AWA Status)' }} />
				<Resource name="rank/seasonname/2024-ironman-703" list={RanksList} options={{ label: '2024 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2023-ironman" list={RanksList} options={{ label: '2023 IRONMAN' }} />
				<Resource name="rank/seasonname/2023-overall" list={RanksList} options={{ label: '2023 Overall (Earning towards 2024 AWA Status)' }} hasShow />
				<Resource name="rank/seasonname/2023-ironman-703" list={RanksList} options={{ label: '2023 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2022-ironman" list={RanksList} options={{ label: '2022 IRONMAN' }} />
				<Resource name="rank/seasonname/2022-overall" list={RanksList} options={{ label: '2022 Overall (2023 AWA Status Holders)' }} />
				<Resource name="rank/seasonname/2022-ironman-703" list={RanksList} options={{ label: '2022 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2021-ironman" list={RanksList} options={{ label: '2021 IRONMAN' }} />
				<Resource name="rank/seasonname/2021-overall" list={RanksList} options={{ label: '2021 Overall (2022 AWA Status Holders)' }} />
				<Resource name="rank/seasonname/2021-ironman-703" list={RanksList} options={{ label: '2021 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2019-overall" list={RanksList} options={{ label: '2019 Overall' }} />
				<Resource name="rank/seasonname/2019-ironman" list={RanksList} options={{ label: '2019 IRONMAN' }} />
				<Resource name="rank/seasonname/2019-ironman-703" list={RanksList} options={{ label: '2019 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2020-ironman" list={RanksList} options={{ label: '2020 IRONMAN' }} />
				<Resource name="rank/seasonname/2020-overall" list={RanksList} options={{ label: '2020 Overall (2021 AWA Status Holders)' }} />
				<Resource name="rank/seasonname/2020-ironman-703" list={RanksList} options={{ label: '2020 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2018-ironman" list={RanksList} options={{ label: '2018 IRONMAN' }} />
				<Resource name="rank/seasonname/2018-overall" list={RanksList} options={{ label: '2018 Overall' }} />
				<Resource name="rank/seasonname/2018-ironman-703" list={RanksList} options={{ label: '2018 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2017-ironman" list={RanksList} options={{ label: '2017 IRONMAN' }} />
				<Resource name="rank/seasonname/2017-overall" list={RanksList} options={{ label: '2017 Overall' }} />
				<Resource name="rank/seasonname/2017-ironman-703" list={RanksList} options={{ label: '2017 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2016-ironman" list={RanksList} options={{ label: '2016 IRONMAN' }} />
				<Resource name="rank/seasonname/2016-overall" list={RanksList} options={{ label: '2016 Overall' }} />
				<Resource name="rank/seasonname/2016-ironman-703" list={RanksList} options={{ label: '2016 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2015-ironman" list={RanksList} options={{ label: '2015 IRONMAN' }} />
				<Resource name="rank/seasonname/2015-overall" list={RanksList} options={{ label: '2015 Overall' }} />
				<Resource name="rank/seasonname/2015-ironman-703" list={RanksList} options={{ label: '2015 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2014-ironman" list={RanksList} options={{ label: '2014 IRONMAN' }} />
				<Resource name="rank/seasonname/2014-overall" list={RanksList} options={{ label: '2014 Overall' }} />
				<Resource name="rank/seasonname/2014-ironman-703" list={RanksList} options={{ label: '2014 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2013-ironman" list={RanksList} options={{ label: '2013 IRONMAN' }} />
				<Resource name="rank/seasonname/2013-overall" list={RanksList} options={{ label: '2013 Overall' }} />
				<Resource name="rank/seasonname/2013-ironman-703" list={RanksList} options={{ label: '2013 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2012-ironman" list={RanksList} options={{ label: '2012 IRONMAN' }} />
				<Resource name="rank/seasonname/2012-overall" list={RanksList} options={{ label: '2012 Overall' }} />
				<Resource name="rank/seasonname/2012-ironman-703" list={RanksList} options={{ label: '2012 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2011-ironman" list={RanksList} options={{ label: '2011 IRONMAN' }} />
				<Resource name="rank/seasonname/2011-overall" list={RanksList} options={{ label: '2011 Overall' }} />
				<Resource name="rank/seasonname/2011-ironman-703" list={RanksList} options={{ label: '2011 IRONMAN 70.3' }} />
				<Resource name="rank/seasonname/2015-5i50-and-international" list={RanksList} options={{ label: '2015 5i50 and International' }} />
				<Resource name="rank/seasonname/2015-sprint-triathlon" list={RanksList} options={{ label: '2015 Sprint Triathlon' }} />
			</Admin>
		</BrowserRouter>
	)
}
export default App
