import makeStyles from './../makeStyles'
import { countryChoices } from '../countryChoices'
import { CountryOptionRenderer } from '../countryOptionRenderer'
import { useFilterStyles } from '../filterStyling'
import { AGRChoices } from '../dynamicAgeGroupChoicesRanks'
import {Filter, SelectInput, TextInput} from 'react-admin'
import {InputAdornment} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
const useStyles = makeStyles({
	resultFilters: {
		padding: '2px'
	}
})
export const RanksFilter = () => {
	const classes = useStyles()
	const filterClasses = useFilterStyles()
	if (AGRChoices){
		return (
			<Filter margin="normal" variant="outlined" className={filterClasses.filterOneLine}>
				<TextInput source="$search" label="Search Athlete" alwaysOn resettable margin="normal" variant="outlined" className={classes.resultFilters} InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon color="disabled" />
						</InputAdornment>
					)
				}} />
				<SelectInput source="AgeGroup" label="Category" choices={AGRChoices} alwaysOn resettable margin="normal" variant="outlined" translateChoice={false} className={classes.resultFilters} />
				<SelectInput source="CountryRepresentingIsoNumeric" label="Country" choices={countryChoices} translateChoice={false} optionText={CountryOptionRenderer} margin="normal" variant="outlined" optionValue={'id'} resettable alwaysOn className={classes.resultFilters} />
			</Filter>
		)
	}
}
export default RanksFilter