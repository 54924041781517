import { useFilterStyles } from '../filterStyling'
import {Filter, TextInput} from 'react-admin'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
export const ResultsFilter = (props) => {
	const filterClasses = useFilterStyles()
	return (
		<Filter {...props} margin="normal" variant="outlined" className={filterClasses.filterOneLine}>
			<TextInput label="Search Bib" source="BibNumber" alwaysOn resettable margin="normal" variant="outlined" InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon color="disabled" />
					</InputAdornment>
				)
			}} />
		</Filter>
	)
}
export default ResultsFilter