import makeStyles from './../makeStyles'
import { TextField, Datagrid, NumberField, ReferenceManyField, Pagination, useRecordContext  } from 'react-admin'
import ContactResultCard from './contactResultCard'
const ClubPointsPagination = props => <Pagination rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200, 500, 1000]} {...props} />
const useStyles = makeStyles({
	center: {
		maxWidth: '33%',
		minWidth: '33%',
		flexBasis: '33%',
		width: '100%',
		padding: '8px',
		textAlign: 'center !important;',
		'&:hover': {
			color: '#e31837'
		}
	},
	tableContainer: {
		tableLayout: 'fixed'
	}
})
export const ContactResultShow2 = () => {
	const record = useRecordContext()
	const ClubPointsID = record.ClubPointsID
	const SubEventID = record.SubEvent
	const classes = useStyles()
	return (
		<>
			<ReferenceManyField label="Results" reference="clubpointsdetail" perPage={25} pagination={<ClubPointsPagination />} filter={{ SubEventID: SubEventID, ClubPointsID: ClubPointsID }} className={classes.tableContainer} target="ClubPointsID" sort={{ field: 'Points', order: 'DESC' }}>
				<Datagrid classes={{ table: classes.tableContainer }} rowClick="expand" expand={<ContactResultCard />} optimized expandSingle bulkActionButtons={false}>
					<TextField source="Contact.FullName" label="Athlete" headerClassName={classes.center} cellClassName={classes.center} sortable={false} />
					<NumberField source="ClubActivityType" label="Status" headerClassName={classes.center} cellClassName={classes.center} sortable={false} />
					<NumberField source="Points" label="Points" headerClassName={classes.center} cellClassName={classes.center} />
				</Datagrid>
			</ReferenceManyField>
		</>
	)
}
export default ContactResultShow2