import {Link} from '@mui/material'
import { useRecordContext } from 'react-admin'
export const EventsResultsLinkOpenDiv = () => {
	const record = useRecordContext()
	if (record && record.Alias) {
		return	<Link target="_blank" rel="noopener noreferrer" href={record ? `/result/subevent/opendivision/${record.SubEventId}` : ''}>
                Results Open Division
		</Link>
	} else{
		return <p></p>
	}
}
export default EventsResultsLinkOpenDiv