import makeStyles from '../makeStyles'
import {List, Datagrid, TextField, NumberField} from 'react-admin'
import ClubPointsFilter2 from './clubPointsFilter2'
import ContactRankingsShowAll3 from './contactRankingsShowAll3'
const useStyles = makeStyles({
	centerTop: {
		maxWidth: '25%',
		minWidth: '25%',
		flexBasis: '25%',
		width: '100%',
		textAlign: 'left !important;',
		'&:hover': {
			color: '#e31837'
		}
	}
})
export const ClubPointsList3 = (props) => {
	const classes = useStyles()
	return (
		<List filters={<ClubPointsFilter2 />} sort={{ field: 'Points', order: 'DESC' }} title=" " perPage={10} exporter={false} emptyWhileLoading disableAuthentication>
			<Datagrid expand={<ContactRankingsShowAll3 />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
				<TextField source="Club" label="Club Name" cellClassName={classes.centerTop} headerClassName={classes.centerTop} />
				<NumberField source="Athletes" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Season Points" />
				<NumberField source="Points" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Season Points" />
			</Datagrid>
		</List>
	)
}
export default ClubPointsList3