import {Filter, TextInput, NumberInput} from 'react-admin'
export const EventsFilter = (props) => (
	<Filter {...props} variant="outlined" >
		<TextInput label="Search" source="$search" alwaysOn resettable variant="outlined" />
		<TextInput label="Search Series" source="Series" resettable variant="outlined" />
		<TextInput label="Search SubEvent" source="SubEvent" resettable variant="outlined" />
		<TextInput label="Search Region" source="BusinessUnit" resettable variant="outlined" />
		<TextInput label="Search Alias" source="Alias" resettable alwaysOn variant="outlined" />
		<TextInput label="Search City" source="City" resettable variant="outlined" />
		<TextInput label="Search Country" source="Country" resettable variant="outlined" />
		<TextInput label="Search Sport" source="Sport" resettable variant="outlined" />
		<NumberInput source="DistanceInMiles" label=" Search Distance In Miles" variant="outlined" />
		<NumberInput source="DistanceInKM" label="Search Distance In KM" variant="outlined" />
	</Filter>
)
export default EventsFilter