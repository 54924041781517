import makeStyles from '../makeStyles'
import { TextField, Datagrid, NumberField, ReferenceManyField, Pagination, useRecordContext } from 'react-admin'
import ContactResultCard from './contactResultCard'
const useStyles = makeStyles({
	center: {
		maxWidth: '33%',
		minWidth: '33%',
		flexBasis: '33%',
		width: '100%',
		textAlign: 'center',
		'&:hover': {
			color: '#e31837'
		}
	}
})
const ClubPointsPaginatoin = props => <Pagination rowsPerPageOptions={[]} {...props} />
export const ContactRankingsShowAll3 = () => {
	const record = useRecordContext()
	const ClubPointsID = record.ClubPointsID
	const SubEventID = record.SubEvent
	const classes = useStyles()
	return (
		<ReferenceManyField label="Details" reference="clubpointsdetail" target="clubpointsid" id="clubpointsid" perPage={1000} sort={{ field: 'Points', order: 'DESC' }} pagination={<ClubPointsPaginatoin />} filter={{ SubEventID: SubEventID, ClubPointsID: ClubPointsID }}>
			<Datagrid expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
				<TextField source="Contact.FullName" label="Athlete" cellClassName={classes.center} headerClassName={classes.center} sortable={false} />
				<NumberField source="Points" label="Points" cellClassName={classes.center} headerClassName={classes.center} sortable={false} />
			</Datagrid>
		</ReferenceManyField>
	)
}
export default ContactRankingsShowAll3