import {
	useListContext,
	TextInput
} from 'react-admin'
import makeStyles from './../makeStyles'
import { theme } from '../../../newTheme'
import { useForm, FormProvider } from 'react-hook-form'
import {Button, Box, InputAdornment} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
const useStyles = makeStyles({
	header: {
		textAlign: 'center',
		margin: '25px',
		backgroundColor: 'transparent !important'
	},
	formFullWidth: {
		width: '100%',
		marginTop: '-55px',
		zIndex: '99999'
	},
	accordian: {
		backgroundColor: theme.palette.background.default,
		flexWrap: 'wrap',
		width: '100%'
	},
	filterButtons: {
		margin: '5px',
		maxHeight: '45px'
	}
})
export const ClubFilterForm = ({ open }) => {
	const {
		filterValues,
		setFilters,
	} = useListContext()
	const classes = useStyles()
	const onSubmit = values => {
		if (Object.keys(values).length > 0) {
			setFilters(values)
		}
	}
	const form = useForm({
		defaultValues: filterValues,
	})
	return (
		<Box className={classes.formFullWidth}>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className={classes.formFullWidth}>
					<Box mt={8} />
					<Box display="flex" alignItems="baseline" flexWrap="wrap" mb={1}>
						<Box flexDirection="column" display="flex" component="span" width="auto" minWidth="30%" mr={2} pr={1} pl={1}>
							<TextInput
								resettable
								variant="outlined"
								size="normal"
								helperText="Search by club name"
								source="$search"
								label="Club Name"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<SearchIcon color="disabled" />
										</InputAdornment>
									)
								}}
							/>
						</Box>
						<Box flexDirection="column" display="flex" component="span" width="auto" minWidth="30%" mr={2} pr={1} pl={1}>
							<TextInput
								resettable
								variant="outlined"
								size="normal"
								label="Search by Location"
								source="$searchAllLocation"
								helperText="Search City, State, or Country"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<SearchIcon color="disabled" />
										</InputAdornment>
									)
								}}
							/>
						</Box>
						<Button variant="outlined" size="large" color="primary" type="submit" className={classes.filterButtons}>
							Search
						</Button>
					</Box>
				</form>
			</FormProvider>
		</Box>
	)
}
export default ClubFilterForm