import makeStyles from './../makeStyles'
import {Avatar, Typography} from '@mui/material'
import { useRecordContext } from 'react-admin'
const useStyles = makeStyles({
	gold: {
		color: '#e0ad1f !important',
		backgroundColor: '#e0ad1f !important',
		verticalAlign: 'middle',
		float: 'left',
		filter: 'alpha(opacity = 50)',
		opacity: '.5',
		maxWidth: '12px',
		maxHeight: '12px',
		display: 'inline'
	},
	silver: {
		color: '#9a9a9a !important',
		backgroundColor: '#9a9a9a !important',
		verticalAlign: 'middle',
		float: 'left',
		filter: 'alpha(opacity = 50)',
		opacity: '.5',
		maxWidth: '12px',
		maxHeight: '12px',
		display: 'inline'
	},
	bronze: {
		color: '#c45928 !important',
		backgroundColor: '#c45928 !important',
		verticalAlign: 'middle',
		float: 'left',
		filter: 'alpha(opacity = 50)',
		opacity: '.5',
		maxWidth: '12px',
		maxHeight: '12px',
		display: 'inline'
	},
	rowCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
	inline: {
		display: 'inline'
	}
})
export const AwaIcon2 = () => {
	const record = useRecordContext()
	const classes = useStyles()
	return (
		record && record.AWAStatus === 'Gold' ? (
			<Typography component="span" className={classes.rowCell}><Avatar className={classes.gold}></Avatar></Typography>
		) : record && record.AWAStatus === 'Silver' ? (
			<Typography component="span" className={classes.rowCell}><Avatar className={classes.silver}></Avatar></Typography>
		) : record && record.AWAStatus === 'Bronze' ? (
			<Typography component="span" className={classes.rowCell}><Avatar className={classes.bronze}></Avatar></Typography>
		) : (
			''
		)
	)
}
export default AwaIcon2