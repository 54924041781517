import makeStyles from '../makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetOne, List, Loading, TextField, Datagrid } from 'react-admin'
import CardShowRun from './cardShowRun'
import FancyFlagField from './fancyFlag'
import ResultsFilterOpenDiv from './resultsFilterOpenDiv'
import ResultCountryISO from './resultCountryISO'
import ResultContactName from './resultContactName'
import ContactResultCard from '../contacts/contactResultCard'
const useStyles = makeStyles({
	headerCell: {
		textAlign: 'left'
	},
	flag: {
		paddingTop: '5px',
		padding: '6px',
		verticalAlign: 'middle',
		'& img': {
			verticalAlign: 'middle'
		}
	},
	rowCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
})
export const PublicResultsListOpenDiv = (props) => {
	const classes = useStyles()
	const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'))
	const subeventId = window.location.pathname.split('/').pop()
	const { data, isLoading, error } = useGetOne('events', { id: subeventId })
	if (isLoading) { return <Loading /> }
	if (error) { return <p>ERROR</p> }
	if (data.SubEvent && data.SubEvent.includes('Rock')) {
		return (
			<List {...props} filters={<ResultsFilterOpenDiv />} perPage={1} title={data.SubEvent} pagination={false} empty={false}
				exporter={false} sort={false}>
				{smDown ? (
					<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<FancyFlagField cellClassName={classes.flag} />
						<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				) : (
					<Datagrid id="resultList" expand={<CardShowRun />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryISO2" label="Country" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<FancyFlagField cellClassName={classes.flag} />
						<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				)}
			</List>
		)
	} else if (data) {
		return (
			<List {...props} filters={<ResultsFilterOpenDiv />} perPage={10} pagination={false} title={data.SubEvent} empty={false}
				exporter={false} sort={false}>
				{smDown ? (
					<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<FancyFlagField cellClassName={classes.flag} />
						<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				) : (
					<Datagrid id="resultList" expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<ResultContactName label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<ResultCountryISO label="Country" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<FancyFlagField cellClassName={classes.flag} />
						<TextField source="SwimTimeConverted" label="Swim" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="BikeTimeConverted" label="Bike" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				)}
			</List>
		)
	}
}
export default PublicResultsListOpenDiv