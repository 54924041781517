import makeStyles from './../makeStyles'
import { ChipField, useRecordContext } from 'react-admin'
import { Box } from '@mui/material'
import AwaBadge from '../../components/ranks/awaBadge'
import CertButton from './certButton'
const useStyles = makeStyles({
	Points: {
		maxWidth: '100px',
		float: 'left'
	},
	Badge: {
		maxWidth: '300px',
		float: 'left'
	}
})
export const RanksSmallSection = (props) => {
	const record = useRecordContext()
	const classes = useStyles()
	const {loading,setLoading} = props
	return (
		<Box sx={{mt:2,ml:2}}>
			<ChipField source="Points" label="Points" className={classes.Points} {...props} />
			<AwaBadge className={classes.Badge} {...props} />
			<CertButton loading={loading} setLoading={setLoading} className={classes.Badge} {...record} />
		</Box>
	)
}
export default RanksSmallSection