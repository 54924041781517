import {
	createTheme
} from '@mui/material/styles'
import { defaultTheme } from 'react-admin'
export const theme = createTheme({
	...defaultTheme,
	palette: {
		mode: 'light',
		primary: {
			main: '#e31837',
		},
	},
	typography: {
		fontFamily: 'Titillium Web'
	},
	components: {
		...defaultTheme.components,
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					backgroundColor: 'unset !important',
					color: 'black',
					MuiButtonBase: {
						root: {
							display: 'none'
						}
					},
				},
				gutters: {
					padding: '0px !important'
				}
			},
		},
		RaSidebar: {
			styleOverrides: {
				root: {
					display: 'none'
				}
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					textAlign: 'left'
				}
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					width: '100%'
				},
			},
			defaultProps: {
				size: 'medium',
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: 'unset'
				},
				colorSecondary: {
					backgroundColor: 'white !important;'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				elevation1: {
					boxShadow: 'unset'
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				shrink: {
					fontSize: '1.25rem',
					fontWeight: '500',
					lineHeight: '1.6',
					letterSpacing: '0.0075em',
					fontFamily: 'inherit',
					padding: '5px',
					color: 'inherit'
				}
			}
		},
		RaLayout: {
			styleOverrides: {
				root: {
					backgroundColor: 'white !important;'
				},
				content: {
					paddingRight: '5px'
				},
				appFrame: {
					marginTop: '0 !important'
				}
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '0'
				}
			}
		},
		MuiTable: {
			styleOverrides: {
				root: {
					tableLayout: 'fixed !important'
				}
			}
		},
		RaAppBar: {
			styleOverrides: {
				toolbar: {
					'& button': {
						'&:not(:nth-child(4))': {
							display: 'none'
						}
					}
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				sizeSmall: {
					padding: '5px',
					'&:last-child': {
						paddingRight: '0',
						paddingLeft: '0',
					}
				},
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: 'unset'
				}
			}
		}
	},
})
export default theme