import Flag from 'react-flags'
import { useRecordContext } from 'react-admin'
import { countryChoices } from '../countryChoices'
export const FancyFlagPodium = () => {
	const record = useRecordContext()
	if (record && record.Country){
		return <Flag basePath="/img/flags"
			name={countryChoices.find(item => item.id === record.Country).name}
			format="svg"
			svgSize={32}
			shiny={false}
			width={32}
		/>
	}else{
		return ''
	}
}
export default FancyFlagPodium