import { TextField, SimpleShowLayout, Show, NumberField, ReferenceField } from 'react-admin'
export const ContactRankingsShowAll = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<ReferenceField label="Event" reference="events" source="SubEventId" linkType="show">
				<TextField source="SubEvent" />
			</ReferenceField>
			<TextField source="AgeGroup" label="AgeGroup" />
			<TextField source="CountryRepresentingISONumeric" label="CountryRepresentingISONumeric" />
			<TextField source="BibNumber" label="BibNumber" />
			<TextField source="EventStatus" label="EventStatus" />
			<TextField source="SwimTime" label="SwimTime" />
			<TextField source="Transition1Time" label="Transition1Time" />
			<TextField source="BikeTime" label="BikeTime" />
			<NumberField source="Transition2Time" label="Transition2Time" />
			<TextField source="RunTime" label="RunTime" />
			<TextField source="FinishTime" label="FinishTime" />
			<NumberField source="FinishRankGroup" label="FinishRankGroup" />
			<NumberField source="FinishRankGender" label="FinishRankGender" />
			<NumberField source="FinishRankOverall" label="FinishRankOverall" />
			<NumberField source="SwimRankGroup" label="SwimRankGroup" />
			<NumberField source="SwimRankGender" label="SwimRankGender" />
			<NumberField source="SwimRankOverall" label="SwimRankOverall" />
			<NumberField source="BikeRankGroup" label="BikeRankGroup" />
			<NumberField source="BikeRankGender" label="BikeRankGender" />
			<NumberField source="BikeRankOverall" label="BikeRankOverall" />
			<NumberField source="RunRankGroup" label="RunRankGroup" />
			<NumberField source="RunRankGender" label="RunRankGender" />
			<NumberField source="RunRankOverall" label="RunRankOverall" />
			<NumberField source="RankPoints" label="RankPoints" />
		</SimpleShowLayout>
	</Show>
)
export default ContactRankingsShowAll