import makeStyles from './../makeStyles'
import {useState} from 'react'
import useMediaQuery  from '@mui/material/useMediaQuery'
import { TextField, NumberField, Pagination, Datagrid, ReferenceManyField, useRecordContext } from 'react-admin'
import CardShow from '../../components/results/cardShow'
import RanksSmallSection from '../../components/ranks/ranksSmallSection'
const useStyles = makeStyles({
	headerCell: {
		textAlign: 'center !important',
		textTransform: 'capitalize',
	},
	rowCell: {
		textAlign: 'center !important',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
	left: {
		textAlign: 'center !important',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	}
})
export const RanksShowAll = () => {
	const classes = useStyles()
	const record = useRecordContext()
	if (!record) return null
	record.season = record.SeasonName.replace(/\s+/g, '-')
	if (record.Contact.Masterid) {
		record.ContactId = record.Contact.Masterid
	}
	const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
	const isMed = useMediaQuery(theme => theme.breakpoints.down('md'))
	const isLrg = useMediaQuery(theme => theme.breakpoints.down('lg'))
	const isXL = useMediaQuery(theme => theme.breakpoints.up('xl'))
	const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))
	const [loading,setLoading] = useState(false)
	return (
		<>
			<RanksSmallSection loading={loading} setLoading={setLoading} />
			<ReferenceManyField label="Results" target="ContactId" reference="results" perPage={25} pagination={<Pagination />}
				filter={{ $season: record.season, ContactId: record.ContactId }}
				exporter={false}
				sort={{ field: 'RankPoints', order: 'DESC' }} title=" ">
				{isXs ? (
					<Datagrid expand={<CardShow />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<TextField source="Subevent.SubEvent" label="Event" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Contact.FullName" label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="RankPoints" headerClassName={classes.left} cellClassName={classes.left} label="Points" />
					</Datagrid>
				) : isSmall ? (
					<Datagrid expand={<CardShow />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<TextField source="Subevent.SubEvent" label="Event" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Contact.FullName" label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="RankPoints" headerClassName={classes.left} cellClassName={classes.left} label="Points" />
					</Datagrid>
				) : isMed ? (
					<Datagrid expand={<CardShow />} rowClick="expand" optimzed bulkActionButtons={false} expandSingle>
						<TextField source="Subevent.SubEvent" label="Event" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="AgeGroup" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishTimeConverted" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="SwimTimeConverted" label="Swim" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="BikeTimeConverted" label="Bike" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="RankPoints" headerClassName={classes.left} cellClassName={classes.left} label="Points" />
					</Datagrid>
				) : isLrg ? (
					<Datagrid id="resultList" expand={<CardShow />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<TextField source="Subevent.SubEvent" label="Event" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="SwimTimeConverted" label="Swim" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="BikeTimeConverted" label="Bike" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="RankPoints" headerClassName={classes.left} cellClassName={classes.left} label="Points" />
					</Datagrid>
				) : isXL ? (
					<Datagrid id="resultList" expand={<CardShow />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<TextField source="Subevent.SubEvent" label="Event" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankGroup" label="Div Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankGender" label="Gender Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="FinishRankOverall" label="Overall Rank" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="SwimTimeConverted" label="Swim" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="BikeTimeConverted" label="Bike" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="RunTimeConverted" label="Run" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Total Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="RankPoints" headerClassName={classes.left} cellClassName={classes.left} label="Points" />
					</Datagrid>
				) : (
					<Datagrid expand={<CardShow />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<TextField source="Subevent.SubEvent" label="Event" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Contact.FullName" label="Athlete" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="FinishTimeConverted" label="Finish" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<NumberField source="RankPoints" headerClassName={classes.left} cellClassName={classes.left} label="Points" />
					</Datagrid>
				)}
			</ReferenceManyField>
		</>
	)
}
export default RanksShowAll