import { options, host, instance } from '../../feathersClient'
const season = window.location.pathname.split('/').pop()
const agegroupUrl = host + '/rank/seasonname/agegroups/' + season
export let AGRChoices
(async () => {
	if (window.location.pathname.includes('rank/seasonname') || window.location.pathname.includes('ranks/seasonname')) {
		let response
		response = await instance.get(agegroupUrl, options)
		AGRChoices = response.data
	}
})()
export default AGRChoices