import makeStyles from './components/makeStyles'
import { theme } from '../newTheme'
import {List, TextField, Datagrid, Pagination} from 'react-admin'
import ClubsCountry from './components/clubs/clubsFancyFlag'
import ClubsLocation from './components/clubs/clubsLocation'
import ClubsAdditionalFilters from './components/clubs/clubsAdditionalFilters'
const useStyles = makeStyles({
	row: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
	row2: {
		textAlign: 'left',
		textTransform: 'capitalize',
		borderLeft: '1px inset',
		paddingLeft: '8px',
		borderColor: theme.palette.divider,
		'&:hover': {
			color: '#e31837'
		}
	},
	rowFlag: {
		textAlign: 'left',
		textTransform: 'capitalize',
		maxWidth: '40px',
		paddingLeft: '8px !important',
		borderLeft: '1px inset',
		borderColor: theme.palette.divider,
		'&:hover': {
			color: '#e31837'
		}
	},
	darkHeading: {
		textAlign: 'left',
		backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
		border: '1px groove',
		padding: '8px',
		borderColor: theme.palette.divider
	},
	darkHeadingFlag: {
		maxWidth: '40px',
		textAlign: 'left',
		backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
		borderLeft: '1px groove',
		paddingLeft: '8px !important',
		borderColor: theme.palette.divider
	},
	clubList: {
		zIndex: '99999',
	},
	actions: {
		paddingTop: '50px',
		header: {
			display: 'none'
		}
	}
})
export const ClubsPagination = props => <Pagination rowsPerPageOptions={[3, 5, 10, 15, 20, 25, 50, 100, 250]} {...props} />
export const ClubsList = () => {
	const classes = useStyles()
	return (
		<List filters={<ClubsAdditionalFilters />} title=" " pagination={<ClubsPagination />} exporter={false} className={classes.actions} sort={{ field: 'ClubDivisionName', order: 'asc' }} disableAuthentication emptyWhileLoading>
			<Datagrid rowClick="show" className={classes.clubList} optimized bulkActionButtons={false} expandSingle>
				<TextField source="name" label="Club Name" cellClassName={classes.row} headerClassName={classes.darkHeading} />
				<ClubsLocation label="Location" cellClassName={classes.row2} headerClassName={classes.darkHeading} />
				<ClubsCountry label="Country" cellClassName={classes.rowFlag} headerClassName={classes.darkHeadingFlag} />
			</Datagrid>
		</List>
	)
}
export default ClubsList
