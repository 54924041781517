import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import TagManager from 'react-gtm-module'
import reportWebVitals from './reportWebVitals'
const root = ReactDOM.createRoot(document.getElementById('root'))
const tagManagerArgs = {
	gtmId: 'GTM-KDDF74N'
}
TagManager.initialize(tagManagerArgs)
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
