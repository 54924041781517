import Flag from 'react-flags'
import { useRecordContext } from 'react-admin'
export const FancyFlagField2 = () => {
	const record = useRecordContext()
	if (record && record.Club.Countries && record.Club.Countries.ISO2){
		return <Flag basePath="/img/flags"
			name={record.Club.Countries.ISO2}
			format="svg"
			svgSize={32}
			shiny={false}
			width={32}
			title={record.Club.Countries.ISO2}
			alt={record.Club.Countries.ISO2}
		/>
	}else {
		return ''
	}
}
export default FancyFlagField2