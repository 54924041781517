import { options, host, instance } from '../../feathersClient'
const subeventId = window.location.pathname.split('/').pop()
const agegroupUrl = host + '/results/subevent/agegroups/' + subeventId
export let Choices
(async () => {
	if (window.location.pathname.includes('result/subevent') || window.location.pathname.includes('results/subevent')) {
		let response
		response = await instance.get(agegroupUrl, options)
		Choices = response.data
	}
})()
export default Choices