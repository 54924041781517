import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import {Button} from '@mui/material'
import { useRecordContext } from 'react-admin'
export const SeCodeCopy = () => {
	const record = useRecordContext()
	const [copiedState, setCopiedState] = useState(false)
	const [subEventId] = useState('<iframe src="https://labs.competitor.com/result/subevent/' + record.SubEventId +'" importance="high" loading="lazy" frameborder="0" style="width: 100%; height: 667px;" height="100%" width="100%"></iframe>')
    	return record ? (
    		<div className="secode">
    			<input value={copiedState}
    				onChange={({ target: { value } }) => this.setCopiedState(true)} />
    			<CopyToClipboard text={subEventId}
    				onCopy={() => setCopiedState(true)}>
    				<Button startIcon={<FileCopyOutlinedIcon />} variant="outlined" color="primary" size="small">Results</Button>
    			</CopyToClipboard>
    			{copiedState ? <span style={{ color: 'red', whiteSpace: 'pre', display: 'block', width: '100%', textAlign: 'center' }}>SECode Copied</span> : null}
    		</div>
    	) : null
}
export default SeCodeCopy