import { divisionChoices } from '../divisionChoices'
import { countryChoices } from '../countryChoices'
import { CountryOptionRenderer } from '../countryOptionRenderer'
import { useFilterStyles } from '../filterStyling'
import {Filter, TextInput, SelectInput} from 'react-admin'
export const ClubPointsFilter = (props) => {
	const filterClasses = useFilterStyles()
	return (
		<Filter {...props} variant="outlined" margin="normal" className={filterClasses.filterOneLine}>
			<SelectInput label="Division" source="Division" choices={divisionChoices} alwaysOn resettable variant="outlined" margin="normal" translateChoice={false} />
			<TextInput label="Search Club" source="$search" alwaysOn resettable variant="outlined" margin="normal" />
			<SelectInput source="ClubsCountriesISO2" label="Country" choices={countryChoices} optionValue={countryChoices.name} translateChoice={false} optionText={CountryOptionRenderer} margin="normal" variant="outlined" resettable alwaysOn/>
		</Filter>
	)
}
export default ClubPointsFilter