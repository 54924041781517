export const addUploadFeature = requestHandler => (type, resource, params) => {
	if (type === 'UPDATE') {
		if (params.data.pictures && params.data.pictures.length) {
			const formerPictures = params.data.pictures.filter(p => !(p.rawFile instanceof File))
			const newPictures = params.data.pictures.filter(p => p.rawFile instanceof File)
			return Promise.all(newPictures.map(convertFileToBase64))
				.then(base64Pictures => base64Pictures.map((picture64, index) => ({
					src: picture64,
					title: `${newPictures[index].title}`,
				})))
				.then(transformedNewPictures => requestHandler(type, resource, {
					...params,
					data: {
						...params.data,
						pictures: [...transformedNewPictures, ...formerPictures],
					},
				}))
		}
	}
	if (type === 'CREATE') {
		if (params.data.pictures && params.data.pictures.length) {
			const formerPictures = params.data.pictures.filter(p => !(p.rawFile instanceof File))
			const newPictures = params.data.pictures.filter(p => p.rawFile instanceof File)
			return Promise.all(newPictures.map(convertFileToBase64))
				.then(base64Pictures => base64Pictures.map((picture64, index) => ({
					src: picture64,
					title: `${newPictures[index].title}`,
				})))
				.then(transformedNewPictures => requestHandler(type, resource, {
					...params,
					data: {
						...params.data,
						pictures: [...transformedNewPictures, ...formerPictures],
					},
				}))
		}
	}
	return requestHandler(type, resource, params)
}
export const convertFileToBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader()
	reader.readAsDataURL(file.rawFile)
	reader.onload = () => resolve(reader.result)
	reader.onerror = reject
})
export default addUploadFeature