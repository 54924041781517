import makeStyles from './../makeStyles'
import { TopToolbar } from 'react-admin'
const useStyles = makeStyles({
	negativeTopMargin: {
		marginTop: '-40px',
		maxWidth: '1200px',
		margin: '0 auto'
	}
})
const ClubEditActionsToolBar = ({ basePath, record }) => {
	const classes = useStyles()
	return (
		<TopToolbar className={classes.negativeTopMargin}>
		</TopToolbar>
	)
}
export default ClubEditActionsToolBar