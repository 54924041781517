import { useRecordContext } from 'react-admin'
import Flag from 'react-flags'
export const RanksFancyFlagField = () => {
	const record = useRecordContext()
	if (record && record.CountryRepresenting !== 'unknown' && !(record.Year > 2021 && (record.Country.ISO2 === 'BY' || record.Country.ISO2 === 'RU'))){
		return <Flag basePath="/img/flags"
			name={record.Country.ISO2}
			format="svg"
			svgSize={32}
			shiny={false}
			width={32}
			title={record.Country.ISO2}
			alt={record.Country.ISO2}
		/>
	}else {
		return ''
	}
}
export default RanksFancyFlagField