import { Component } from 'react'
import '../../styles/cardShow.css'
import countries from 'i18n-iso-countries'
class CardShowPodium extends Component{
	constructor(props) {
		super(props)
		this.state = { countryCode: this.props.record.CountryISO2, countrySet: false }
		this.toggleDetails = this.toggleDetails.bind(this)
	}
	componentDidMount() {
		let countryCodeA2 = this.props.record.CountryISO2
		if(countries.isValid(countryCodeA2)){
			this.setState(
				{countryCode: countryCodeA2,
					countrySet: true
				}
			)
		}
	}
	toggleDetails(e){
		e.preventDefault()
		let toggle = function (elem) {
			elem.classList.toggle('is-visible')
		}
		let parentRow = e.target.parentNode.parentNode
		let content = parentRow.querySelector(e.target.hash)
		e.target.parentElement.classList.toggle('toggled')
		if (!content) return
		toggle(content)
	}
	render(){
		const FinishTime = this.props.record.FinishTimeConverted
		const SwimRankGender = this.props.record.SwimRankGender
		const SwimRankGroup = this.props.record.SwimRankGroup
		const SwimRankOverall = this.props.record.SwimRankOverall
		const SwimTime = this.props.record.SwimTimeConverted
		const BikeRankGender = this.props.record.BikeRankGender
		const BikeRankGroup = this.props.record.BikeRankGroup
		const BikeRankOverall = this.props.record.BikeRankOverall
		const BikeTime = this.props.record.BikeTimeConverted
		const RunRankGender = this.props.record.RunRankGender
		const RunRankGroup = this.props.record.RunRankGroup
		const RunRankOverall = this.props.record.RunRankOverall
		const RunTime = this.props.record.RunTimeConverted
		return (
			<div className="resultContainer">
				<div id={this.props.record.id} className={'raceSum tableWrap'}>
					<div className="summaryHeaderCont">
						<h3>Race Summary</h3>
					</div>
				</div>
				<div className="summaryRow">
					<p className="summaryHeading">Swim</p>
					<p className="summaryTime">{SwimTime}</p>
					<a href="#swimDetails" className={'toggle detailsButton'} onClick={this.toggleDetails}></a>
				</div>
				<div id="swimDetails" className={'toggleContent detailsRow'}>
					<h4>Swim Details | Division Rank:  {SwimRankGroup}</h4>
					<div className="table">
						<div className={'tableRow tableHeader'}>
							<div className="wrapper">
								<div className="text">Race Time</div>
								<div className="text">Division Rank</div>
								<div className="text">Gender Rank</div>
								<div className="text">Overall Rank</div>
							</div>
						</div>
						<div className={'tableRow tableFooter'}>
							<div className="wrapper">
								<div className="text">{SwimTime}</div>
								<div className="text">{SwimRankGroup}</div>
								<div className="text">{SwimRankGender}</div>
								<div className="text">{SwimRankOverall}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="summaryRow">
					<p className="summaryHeading">Bike</p>
					<p className="summaryTime">{BikeTime}</p>
					<a href="#bikeDetails" className={'toggle detailsButton'} onClick={this.toggleDetails}></a>
				</div>
				<div id="bikeDetails" className={'toggleContent detailsRow'}>
					<h4>Bike Details | Division Rank:  {BikeRankGroup}</h4>
					<div className="table">
						<div className={'tableRow tableHeader'}>
							<div className="wrapper">
								<div className="text">Race Time</div>
								<div className="text">Division Rank</div>
								<div className="text">Gender Rank</div>
								<div className="text">Overall Rank</div>
							</div>
						</div>
						<div className={'tableRow tableFooter'}>
							<div className="wrapper">
								<div className="text">{BikeTime}</div>
								<div className="text">{BikeRankGroup}</div>
								<div className="text">{BikeRankGender}</div>
								<div className="text">{BikeRankOverall}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="summaryRow">
					<p className="summaryHeading">Run</p>
					<p className="summaryTime">{RunTime}</p>
					<a href="#runDetails" className={'toggle detailsButton'} onClick={this.toggleDetails}></a>
				</div>
				<div id="runDetails" className={'toggleContent detailsRow'}>
					<h4>Run Details | Division Rank:  {RunRankGroup}</h4>
					<div className="table">
						<div className={'tableRow tableHeader'}>
							<div className="wrapper">
								<div className="text">Race Time</div>
								<div className="text">Division Rank</div>
								<div className="text">Gender Rank</div>
								<div className="text">Overall Rank</div>
							</div>
						</div>
						<div className={'tableRow tableFooter'}>
							<div className="wrapper">
								<div className="text">{RunTime}</div>
								<div className="text">{RunRankGroup}</div>
								<div className="text">{RunRankGender}</div>
								<div className="text">{RunRankOverall}</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'summaryRow overallRow'}>
					<p className="summaryHeading">Overall</p>
					<p className="summaryTime">{FinishTime}</p>
				</div>
			</div>
		)
	}
}
export default CardShowPodium
