import { useRecordContext } from 'react-admin'
import {Link} from '@mui/material'
export const EventsPodiumAwardsLink = () => {
	const record = useRecordContext()
	if (record?.Alias){
		return <Link target="_blank" rel="noopener noreferrer" href={record ? `/clubpointsdetail/podium/subevent/${record.SubEventId}` : ''}>
			Podium Awards
		</Link>
	}else{
		return <p></p>
	}
}
export default EventsPodiumAwardsLink