import {
	useRefresh,
	useNotify,
	Create
} from 'react-admin'
import ClubMultiStepForm from './clubMultiStepForm'
export const ClubMultiStepFormActual = props => {
	const notify = useNotify()
	const refresh = useRefresh()
	const onSuccess = () => {
		notify('New Club Info Sent to Primary Contact Email for Confirmation. If you are the primary contact please check your email and confirm your submission.')
		refresh()
	}
	const onFailure = (error) => {
		notify(`Could not create club: ${error.message}`, 'error', 30000)
	}
	return (
		<Create {...props} title=" " mutationOptions={{ onSuccess: onSuccess, onError: onFailure }} >
			<ClubMultiStepForm />
		</Create>
	)
}
export default ClubMultiStepFormActual