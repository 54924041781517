import { useRecordContext } from 'react-admin'
import Flag from 'react-flags'
export const FancyFlagField = () => {
	const record = useRecordContext()
	if (record && record.Country && record.Country.ISO2){
		return <Flag basePath="/img/flags"
			name={record.Country.ISO2}
			format="svg"
			svgSize={32}
			shiny={false}
			width={32}
			alt={record.Country.ISO2}
			title={record.Country.ISO2}
		/>
	}else{
		return ''
	}
}
export default FancyFlagField