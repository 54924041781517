export const countryChoices = [
	{
		'fullname': 'Afghanistan',
		'BusinessUnitName': 'Asia',
		'id': 4,
		'name': 'AF'
	},
	{
		'fullname': 'Aland Islands',
		'BusinessUnitName': 'Europe',
		'id': 248,
		'name': 'AX'
	},
	{
		'fullname': 'Albania',
		'BusinessUnitName': 'Europe',
		'id': 8,
		'name': 'AL'
	},
	{
		'fullname': 'Algeria',
		'BusinessUnitName': 'Africa',
		'id': 12,
		'name': 'DZ'
	},
	{
		'fullname': 'American Samoa',
		'BusinessUnitName': 'Asia',
		'id': 16,
		'name': 'AS'
	},
	{
		'fullname': 'Andorra',
		'BusinessUnitName': 'Europe',
		'id': 20,
		'name': 'AD'
	},
	{
		'fullname': 'Angola',
		'BusinessUnitName': 'Africa',
		'id': 24,
		'name': 'AO'
	},
	{
		'fullname': 'Anguilla',
		'BusinessUnitName': 'Latin America',
		'id': 660,
		'name': 'AI'
	},
	{
		'fullname': 'Antarctica',
		'BusinessUnitName': 'Asia',
		'id': 10,
		'name': 'AQ'
	},
	{
		'fullname': 'Antigua and Barbuda',
		'BusinessUnitName': 'Latin America',
		'id': 28,
		'name': 'AG'
	},
	{
		'fullname': 'Argentina',
		'BusinessUnitName': 'Latin America',
		'id': 32,
		'name': 'AR'
	},
	{
		'fullname': 'Armenia',
		'BusinessUnitName': 'Asia',
		'id': 51,
		'name': 'AM'
	},
	{
		'fullname': 'Aruba',
		'BusinessUnitName': 'Latin America',
		'id': 533,
		'name': 'AW'
	},
	{
		'fullname': 'Australia',
		'BusinessUnitName': 'Oceania',
		'id': 36,
		'name': 'AU'
	},
	{
		'fullname': 'Austria',
		'BusinessUnitName': 'Europe',
		'id': 40,
		'name': 'AT'
	},
	{
		'fullname': 'Azerbaijan',
		'BusinessUnitName': 'Asia',
		'id': 31,
		'name': 'AZ'
	},
	{
		'fullname': 'Bahamas',
		'BusinessUnitName': 'Latin America',
		'id': 44,
		'name': 'BS'
	},
	{
		'fullname': 'Bahrain',
		'BusinessUnitName': 'Middle East',
		'id': 48,
		'name': 'BH'
	},
	{
		'fullname': 'Bangladesh',
		'BusinessUnitName': 'Asia',
		'id': 50,
		'name': 'BD'
	},
	{
		'fullname': 'Barbados',
		'BusinessUnitName': 'Latin America',
		'id': 52,
		'name': 'BB'
	},
	{
		'fullname': 'Belarus',
		'BusinessUnitName': 'Europe',
		'id': 112,
		'name': 'BY'
	},
	{
		'fullname': 'Belgium',
		'BusinessUnitName': 'Europe',
		'id': 56,
		'name': 'BE'
	},
	{
		'fullname': 'Belize',
		'BusinessUnitName': 'Latin America',
		'id': 84,
		'name': 'BZ'
	},
	{
		'fullname': 'Benin',
		'BusinessUnitName': 'Africa',
		'id': 204,
		'name': 'BJ'
	},
	{
		'fullname': 'Bermuda',
		'BusinessUnitName': 'Latin America',
		'id': 60,
		'name': 'BM'
	},
	{
		'fullname': 'Bhutan',
		'BusinessUnitName': 'Asia',
		'id': 64,
		'name': 'BT'
	},
	{
		'fullname': 'Bolivia',
		'BusinessUnitName': 'Latin America',
		'id': 68,
		'name': 'BO'
	},
	{
		'fullname': 'Bonaire, Saint Eustatius and Saba',
		'BusinessUnitName': 'Latin America',
		'id': 535,
		'name': 'BQ'
	},
	{
		'fullname': 'Bosnia and Herzegovina',
		'BusinessUnitName': 'Europe',
		'id': 70,
		'name': 'BA'
	},
	{
		'fullname': 'Botswana',
		'BusinessUnitName': 'Africa',
		'id': 72,
		'name': 'BW'
	},
	{
		'fullname': 'Bouvet Island',
		'BusinessUnitName': 'Asia',
		'id': 74,
		'name': 'BV'
	},
	{
		'fullname': 'Brazil',
		'BusinessUnitName': 'Latin America',
		'id': 76,
		'name': 'BR'
	},
	{
		'fullname': 'British Indian Ocean Territory',
		'BusinessUnitName': 'Asia',
		'id': 86,
		'name': 'IO'
	},
	{
		'fullname': 'British Virgin Islands',
		'BusinessUnitName': 'Latin America',
		'id': 92,
		'name': 'VG'
	},
	{
		'fullname': 'Brunei',
		'BusinessUnitName': 'Asia',
		'id': 96,
		'name': 'BN'
	},
	{
		'fullname': 'Bulgaria',
		'BusinessUnitName': 'Europe',
		'id': 100,
		'name': 'BG'
	},
	{
		'fullname': 'Burkina Faso',
		'BusinessUnitName': 'Africa',
		'id': 854,
		'name': 'BF'
	},
	{
		'fullname': 'Burundi',
		'BusinessUnitName': 'Africa',
		'id': 108,
		'name': 'BI'
	},
	{
		'fullname': 'Cambodia',
		'BusinessUnitName': 'Asia',
		'id': 116,
		'name': 'KH'
	},
	{
		'fullname': 'Cameroon',
		'BusinessUnitName': 'Africa',
		'id': 120,
		'name': 'CM'
	},
	{
		'fullname': 'Canada',
		'BusinessUnitName': 'North America',
		'id': 124,
		'name': 'CA'
	},
	{
		'fullname': 'Cape Verde',
		'BusinessUnitName': 'Africa',
		'id': 132,
		'name': 'CV'
	},
	{
		'fullname': 'Cayman Islands',
		'BusinessUnitName': 'Latin America',
		'id': 136,
		'name': 'KY'
	},
	{
		'fullname': 'Central African Republic',
		'BusinessUnitName': 'Africa',
		'id': 140,
		'name': 'CF'
	},
	{
		'fullname': 'Chad',
		'BusinessUnitName': 'Africa',
		'id': 148,
		'name': 'TD'
	},
	{
		'fullname': 'Chile',
		'BusinessUnitName': 'Latin America',
		'id': 152,
		'name': 'CL'
	},
	{
		'fullname': 'China',
		'BusinessUnitName': 'China',
		'id': 156,
		'name': 'CN'
	},
	{
		'fullname': 'Christmas Island',
		'BusinessUnitName': 'Asia',
		'id': 162,
		'name': 'CX'
	},
	{
		'fullname': 'Cocos Islands',
		'BusinessUnitName': 'Asia',
		'id': 166,
		'name': 'CC'
	},
	{
		'fullname': 'Colombia',
		'BusinessUnitName': 'Latin America',
		'id': 170,
		'name': 'CO'
	},
	{
		'fullname': 'Comoros',
		'BusinessUnitName': 'Africa',
		'id': 174,
		'name': 'KM'
	},
	{
		'fullname': 'Cook Islands',
		'BusinessUnitName': 'Asia',
		'id': 184,
		'name': 'CK'
	},
	{
		'fullname': 'Costa Rica',
		'BusinessUnitName': 'Latin America',
		'id': 188,
		'name': 'CR'
	},
	{
		'fullname': 'Croatia',
		'BusinessUnitName': 'Europe',
		'id': 191,
		'name': 'HR'
	},
	{
		'fullname': 'Cuba',
		'BusinessUnitName': 'Latin America',
		'id': 192,
		'name': 'CU'
	},
	{
		'fullname': 'Curacao',
		'BusinessUnitName': 'Latin America',
		'id': 531,
		'name': 'CW'
	},
	{
		'fullname': 'Cyprus',
		'BusinessUnitName': 'Europe',
		'id': 196,
		'name': 'CY'
	},
	{
		'fullname': 'Czech Republic',
		'BusinessUnitName': 'Europe',
		'id': 203,
		'name': 'CZ'
	},
	{
		'fullname': 'Democratic Republic of the Congo',
		'BusinessUnitName': 'Africa',
		'id': 180,
		'name': 'CD'
	},
	{
		'fullname': 'Denmark',
		'BusinessUnitName': 'Europe',
		'id': 208,
		'name': 'DK'
	},
	{
		'fullname': 'Djibouti',
		'BusinessUnitName': 'Africa',
		'id': 262,
		'name': 'DJ'
	},
	{
		'fullname': 'Dominica',
		'BusinessUnitName': 'Latin America',
		'id': 212,
		'name': 'DM'
	},
	{
		'fullname': 'Dominican Republic',
		'BusinessUnitName': 'Latin America',
		'id': 214,
		'name': 'DO'
	},
	{
		'fullname': 'East Timor',
		'BusinessUnitName': 'Asia',
		'id': 626,
		'name': 'TL'
	},
	{
		'fullname': 'Ecuador',
		'BusinessUnitName': 'Latin America',
		'id': 218,
		'name': 'EC'
	},
	{
		'fullname': 'Egypt',
		'BusinessUnitName': 'Middle East',
		'id': 818,
		'name': 'EG'
	},
	{
		'fullname': 'El Salvador',
		'BusinessUnitName': 'Latin America',
		'id': 222,
		'name': 'SV'
	},
	{
		'fullname': 'Equatorial Guinea',
		'BusinessUnitName': 'Africa',
		'id': 226,
		'name': 'GQ'
	},
	{
		'fullname': 'Eritrea',
		'BusinessUnitName': 'Africa',
		'id': 232,
		'name': 'ER'
	},
	{
		'fullname': 'Estonia',
		'BusinessUnitName': 'Europe',
		'id': 233,
		'name': 'EE'
	},
	{
		'fullname': 'Ethiopia',
		'BusinessUnitName': 'Africa',
		'id': 231,
		'name': 'ET'
	},
	{
		'fullname': 'Falkland Islands',
		'BusinessUnitName': 'Latin America',
		'id': 238,
		'name': 'FK'
	},
	{
		'fullname': 'Faroe Islands',
		'BusinessUnitName': 'Europe',
		'id': 234,
		'name': 'FO'
	},
	{
		'fullname': 'Fiji',
		'BusinessUnitName': 'Asia',
		'id': 242,
		'name': 'FJ'
	},
	{
		'fullname': 'Finland',
		'BusinessUnitName': 'Europe',
		'id': 246,
		'name': 'FI'
	},
	{
		'fullname': 'France',
		'BusinessUnitName': 'Europe',
		'id': 250,
		'name': 'FR'
	},
	{
		'fullname': 'French Guiana',
		'BusinessUnitName': 'Latin America',
		'id': 254,
		'name': 'GF'
	},
	{
		'fullname': 'French Polynesia',
		'BusinessUnitName': 'Asia',
		'id': 258,
		'name': 'PF'
	},
	{
		'fullname': 'French Southern Territories',
		'BusinessUnitName': 'Asia',
		'id': 260,
		'name': 'TF'
	},
	{
		'fullname': 'Gabon',
		'BusinessUnitName': 'Africa',
		'id': 266,
		'name': 'GA'
	},
	{
		'fullname': 'Gambia',
		'BusinessUnitName': 'Africa',
		'id': 270,
		'name': 'GM'
	},
	{
		'fullname': 'Georgia',
		'BusinessUnitName': 'Asia',
		'id': 268,
		'name': 'GE'
	},
	{
		'fullname': 'Germany',
		'BusinessUnitName': 'Europe',
		'id': 276,
		'name': 'DE'
	},
	{
		'fullname': 'Ghana',
		'BusinessUnitName': 'Africa',
		'id': 288,
		'name': 'GH'
	},
	{
		'fullname': 'Gibraltar',
		'BusinessUnitName': 'Europe',
		'id': 292,
		'name': 'GI'
	},
	{
		'fullname': 'Greece',
		'BusinessUnitName': 'Europe',
		'id': 300,
		'name': 'GR'
	},
	{
		'fullname': 'Greenland',
		'BusinessUnitName': 'Latin America',
		'id': 304,
		'name': 'GL'
	},
	{
		'fullname': 'Grenada',
		'BusinessUnitName': 'Latin America',
		'id': 308,
		'name': 'GD'
	},
	{
		'fullname': 'Guadeloupe',
		'BusinessUnitName': 'Latin America',
		'id': 312,
		'name': 'GP'
	},
	{
		'fullname': 'Guam',
		'BusinessUnitName': 'Asia',
		'id': 316,
		'name': 'GU'
	},
	{
		'fullname': 'Guatemala',
		'BusinessUnitName': 'Latin America',
		'id': 320,
		'name': 'GT'
	},
	{
		'fullname': 'Guernsey',
		'BusinessUnitName': 'Europe',
		'id': 831,
		'name': 'GG'
	},
	{
		'fullname': 'Guinea',
		'BusinessUnitName': 'Africa',
		'id': 324,
		'name': 'GN'
	},
	{
		'fullname': 'Guinea-Bissau',
		'BusinessUnitName': 'Africa',
		'id': 624,
		'name': 'GW'
	},
	{
		'fullname': 'Guyana',
		'BusinessUnitName': 'Latin America',
		'id': 328,
		'name': 'GY'
	},
	{
		'fullname': 'Haiti',
		'BusinessUnitName': 'Latin America',
		'id': 332,
		'name': 'HT'
	},
	{
		'fullname': 'Heard Island and McDonald Islands',
		'BusinessUnitName': 'Asia',
		'id': 334,
		'name': 'HM'
	},
	{
		'fullname': 'Honduras',
		'BusinessUnitName': 'Latin America',
		'id': 340,
		'name': 'HN'
	},
	{
		'fullname': 'Hong Kong',
		'BusinessUnitName': 'Asia',
		'id': 344,
		'name': 'HK'
	},
	{
		'fullname': 'Hungary',
		'BusinessUnitName': 'Europe',
		'id': 348,
		'name': 'HU'
	},
	{
		'fullname': 'Iceland',
		'BusinessUnitName': 'Europe',
		'id': 352,
		'name': 'IS'
	},
	{
		'fullname': 'India',
		'BusinessUnitName': 'Asia',
		'id': 356,
		'name': 'IN'
	},
	{
		'fullname': 'Indonesia',
		'BusinessUnitName': 'Asia',
		'id': 360,
		'name': 'ID'
	},
	{
		'fullname': 'Iran',
		'BusinessUnitName': 'Middle East',
		'id': 364,
		'name': 'IR'
	},
	{
		'fullname': 'Iraq',
		'BusinessUnitName': 'Middle East',
		'id': 368,
		'name': 'IQ'
	},
	{
		'fullname': 'Ireland',
		'BusinessUnitName': 'Europe',
		'id': 372,
		'name': 'IE'
	},
	{
		'fullname': 'Isle of Man',
		'BusinessUnitName': 'Europe',
		'id': 833,
		'name': 'IM'
	},
	{
		'fullname': 'Israel',
		'BusinessUnitName': 'Asia',
		'id': 376,
		'name': 'IL'
	},
	{
		'fullname': 'Italy',
		'BusinessUnitName': 'Europe',
		'id': 380,
		'name': 'IT'
	},
	{
		'fullname': 'Ivory Coast',
		'BusinessUnitName': 'Africa',
		'id': 384,
		'name': 'CI'
	},
	{
		'fullname': 'Jamaica',
		'BusinessUnitName': 'Latin America',
		'id': 388,
		'name': 'JM'
	},
	{
		'fullname': 'Japan',
		'BusinessUnitName': 'Asia',
		'id': 392,
		'name': 'JP'
	},
	{
		'fullname': 'Jersey',
		'BusinessUnitName': 'Europe',
		'id': 832,
		'name': 'JE'
	},
	{
		'fullname': 'Jordan',
		'BusinessUnitName': 'Middle East',
		'id': 400,
		'name': 'JO'
	},
	{
		'fullname': 'Kazakhstan',
		'BusinessUnitName': 'Middle East',
		'id': 398,
		'name': 'KZ'
	},
	{
		'fullname': 'Kenya',
		'BusinessUnitName': 'Africa',
		'id': 404,
		'name': 'KE'
	},
	{
		'fullname': 'Kiribati',
		'BusinessUnitName': 'Asia',
		'id': 296,
		'name': 'KI'
	},
	{
		'fullname': 'Kosovo',
		'BusinessUnitName': 'Europe',
		'id': 0,
		'name': 'XK'
	},
	{
		'fullname': 'Kuwait',
		'BusinessUnitName': 'Middle East',
		'id': 414,
		'name': 'KW'
	},
	{
		'fullname': 'Kyrgyzstan',
		'BusinessUnitName': 'Asia',
		'id': 417,
		'name': 'KG'
	},
	{
		'fullname': 'Laos',
		'BusinessUnitName': 'Asia',
		'id': 418,
		'name': 'LA'
	},
	{
		'fullname': 'Latvia',
		'BusinessUnitName': 'Europe',
		'id': 428,
		'name': 'LV'
	},
	{
		'fullname': 'Lebanon',
		'BusinessUnitName': 'Middle East',
		'id': 422,
		'name': 'LB'
	},
	{
		'fullname': 'Lesotho',
		'BusinessUnitName': 'Africa',
		'id': 426,
		'name': 'LS'
	},
	{
		'fullname': 'Liberia',
		'BusinessUnitName': 'Africa',
		'id': 430,
		'name': 'LR'
	},
	{
		'fullname': 'Libya',
		'BusinessUnitName': 'Africa',
		'id': 434,
		'name': 'LY'
	},
	{
		'fullname': 'Liechtenstein',
		'BusinessUnitName': 'Europe',
		'id': 438,
		'name': 'LI'
	},
	{
		'fullname': 'Lithuania',
		'BusinessUnitName': 'Europe',
		'id': 440,
		'name': 'LT'
	},
	{
		'fullname': 'Luxembourg',
		'BusinessUnitName': 'Europe',
		'id': 442,
		'name': 'LU'
	},
	{
		'fullname': 'Macao',
		'BusinessUnitName': 'Asia',
		'id': 446,
		'name': 'MO'
	},
	{
		'fullname': 'Macedonia',
		'BusinessUnitName': 'Europe',
		'id': 807,
		'name': 'MK'
	},
	{
		'fullname': 'Madagascar',
		'BusinessUnitName': 'Africa',
		'id': 450,
		'name': 'MG'
	},
	{
		'fullname': 'Malawi',
		'BusinessUnitName': 'Africa',
		'id': 454,
		'name': 'MW'
	},
	{
		'fullname': 'Malaysia',
		'BusinessUnitName': 'Asia',
		'id': 458,
		'name': 'MY'
	},
	{
		'fullname': 'Maldives',
		'BusinessUnitName': 'Asia',
		'id': 462,
		'name': 'MV'
	},
	{
		'fullname': 'Mali',
		'BusinessUnitName': 'Africa',
		'id': 466,
		'name': 'ML'
	},
	{
		'fullname': 'Malta',
		'BusinessUnitName': 'Europe',
		'id': 470,
		'name': 'MT'
	},
	{
		'fullname': 'Marshall Islands',
		'BusinessUnitName': 'Asia',
		'id': 584,
		'name': 'MH'
	},
	{
		'fullname': 'Martinique',
		'BusinessUnitName': 'Latin America',
		'id': 474,
		'name': 'MQ'
	},
	{
		'fullname': 'Mauritania',
		'BusinessUnitName': 'Africa',
		'id': 478,
		'name': 'MR'
	},
	{
		'fullname': 'Mauritius',
		'BusinessUnitName': 'Africa',
		'id': 480,
		'name': 'MU'
	},
	{
		'fullname': 'Mayotte',
		'BusinessUnitName': 'Africa',
		'id': 175,
		'name': 'YT'
	},
	{
		'fullname': 'Mexico',
		'BusinessUnitName': 'Latin America',
		'id': 484,
		'name': 'MX'
	},
	{
		'fullname': 'Micronesia',
		'BusinessUnitName': 'Asia',
		'id': 583,
		'name': 'FM'
	},
	{
		'fullname': 'Moldova',
		'BusinessUnitName': 'Europe',
		'id': 498,
		'name': 'MD'
	},
	{
		'fullname': 'Monaco',
		'BusinessUnitName': 'Europe',
		'id': 492,
		'name': 'MC'
	},
	{
		'fullname': 'Mongolia',
		'BusinessUnitName': 'Asia',
		'id': 496,
		'name': 'MN'
	},
	{
		'fullname': 'Montenegro',
		'BusinessUnitName': 'Europe',
		'id': 499,
		'name': 'ME'
	},
	{
		'fullname': 'Montserrat',
		'BusinessUnitName': 'Latin America',
		'id': 500,
		'name': 'MS'
	},
	{
		'fullname': 'Morocco',
		'BusinessUnitName': 'Africa',
		'id': 504,
		'name': 'MA'
	},
	{
		'fullname': 'Mozambique',
		'BusinessUnitName': 'Africa',
		'id': 508,
		'name': 'MZ'
	},
	{
		'fullname': 'Myanmar',
		'BusinessUnitName': 'Asia',
		'id': 104,
		'name': 'MM'
	},
	{
		'fullname': 'Namibia',
		'BusinessUnitName': 'Africa',
		'id': 516,
		'name': 'NA'
	},
	{
		'fullname': 'Nauru',
		'BusinessUnitName': 'Asia',
		'id': 520,
		'name': 'NR'
	},
	{
		'fullname': 'Nepal',
		'BusinessUnitName': 'Asia',
		'id': 524,
		'name': 'NP'
	},
	{
		'fullname': 'Netherlands',
		'BusinessUnitName': 'Europe',
		'id': 528,
		'name': 'NL'
	},
	{
		'fullname': 'Netherlands Antilles',
		'BusinessUnitName': 'Latin America',
		'id': 530,
		'name': 'AN'
	},
	{
		'fullname': 'New Caledonia',
		'BusinessUnitName': 'Asia',
		'id': 540,
		'name': 'NC'
	},
	{
		'fullname': 'New Zealand',
		'BusinessUnitName': 'Oceania',
		'id': 554,
		'name': 'NZ'
	},
	{
		'fullname': 'Nicaragua',
		'BusinessUnitName': 'Latin America',
		'id': 558,
		'name': 'NI'
	},
	{
		'fullname': 'Niger',
		'BusinessUnitName': 'Africa',
		'id': 562,
		'name': 'NE'
	},
	{
		'fullname': 'Nigeria',
		'BusinessUnitName': 'Africa',
		'id': 566,
		'name': 'NG'
	},
	{
		'fullname': 'Niue',
		'BusinessUnitName': 'Asia',
		'id': 570,
		'name': 'NU'
	},
	{
		'fullname': 'Norfolk Island',
		'BusinessUnitName': 'Asia',
		'id': 574,
		'name': 'NF'
	},
	{
		'fullname': 'North Korea',
		'BusinessUnitName': 'Asia',
		'id': 408,
		'name': 'KP'
	},
	{
		'fullname': 'Northern Mariana Islands',
		'BusinessUnitName': 'Asia',
		'id': 580,
		'name': 'MP'
	},
	{
		'fullname': 'Norway',
		'BusinessUnitName': 'Europe',
		'id': 578,
		'name': 'NO'
	},
	{
		'fullname': 'Oman',
		'BusinessUnitName': 'Middle East',
		'id': 512,
		'name': 'OM'
	},
	{
		'fullname': 'Pakistan',
		'BusinessUnitName': 'Asia',
		'id': 586,
		'name': 'PK'
	},
	{
		'fullname': 'Palau',
		'BusinessUnitName': 'Asia',
		'id': 585,
		'name': 'PW'
	},
	{
		'fullname': 'State of Palestine',
		'BusinessUnitName': 'Asia',
		'id': 275,
		'name': 'PS'
	},
	{
		'fullname': 'Panama',
		'BusinessUnitName': 'Latin America',
		'id': 591,
		'name': 'PA'
	},
	{
		'fullname': 'Papua New Guinea',
		'BusinessUnitName': 'Asia',
		'id': 598,
		'name': 'PG'
	},
	{
		'fullname': 'Paraguay',
		'BusinessUnitName': 'Latin America',
		'id': 600,
		'name': 'PY'
	},
	{
		'fullname': 'Peru',
		'BusinessUnitName': 'Latin America',
		'id': 604,
		'name': 'PE'
	},
	{
		'fullname': 'Philippines',
		'BusinessUnitName': 'Asia',
		'id': 608,
		'name': 'PH'
	},
	{
		'fullname': 'Pitcairn',
		'BusinessUnitName': 'Asia',
		'id': 612,
		'name': 'PN'
	},
	{
		'fullname': 'Poland',
		'BusinessUnitName': 'Europe',
		'id': 616,
		'name': 'PL'
	},
	{
		'fullname': 'Portugal',
		'BusinessUnitName': 'Europe',
		'id': 620,
		'name': 'PT'
	},
	{
		'fullname': 'Puerto Rico',
		'BusinessUnitName': 'Latin America',
		'id': 630,
		'name': 'PR'
	},
	{
		'fullname': 'Qatar',
		'BusinessUnitName': 'Middle East',
		'id': 634,
		'name': 'QA'
	},
	{
		'fullname': 'Republic of the Congo',
		'BusinessUnitName': 'Africa',
		'id': 178,
		'name': 'CG'
	},
	{
		'fullname': 'Reunion',
		'BusinessUnitName': 'Africa',
		'id': 638,
		'name': 'RE'
	},
	{
		'fullname': 'Romania',
		'BusinessUnitName': 'Europe',
		'id': 642,
		'name': 'RO'
	},
	{
		'fullname': 'Russia',
		'BusinessUnitName': 'Europe',
		'id': 643,
		'name': 'RU'
	},
	{
		'fullname': 'Rwanda',
		'BusinessUnitName': 'Africa',
		'id': 646,
		'name': 'RW'
	},
	{
		'fullname': 'Saint Barthelemy',
		'BusinessUnitName': 'Latin America',
		'id': 652,
		'name': 'BL'
	},
	{
		'fullname': 'Saint Helena',
		'BusinessUnitName': 'Africa',
		'id': 654,
		'name': 'SH'
	},
	{
		'fullname': 'Saint Kitts and Nevis',
		'BusinessUnitName': 'Latin America',
		'id': 659,
		'name': 'KN'
	},
	{
		'fullname': 'Saint Lucia',
		'BusinessUnitName': 'Latin America',
		'id': 662,
		'name': 'LC'
	},
	{
		'fullname': 'Saint Martin',
		'BusinessUnitName': 'Latin America',
		'id': 663,
		'name': 'MF'
	},
	{
		'fullname': 'Saint Pierre and Miquelon',
		'BusinessUnitName': 'Latin America',
		'id': 666,
		'name': 'PM'
	},
	{
		'fullname': 'Saint Vincent and the Grenadines',
		'BusinessUnitName': 'Latin America',
		'id': 670,
		'name': 'VC'
	},
	{
		'fullname': 'Samoa',
		'BusinessUnitName': 'Asia',
		'id': 882,
		'name': 'WS'
	},
	{
		'fullname': 'San Marino',
		'BusinessUnitName': 'Europe',
		'id': 674,
		'name': 'SM'
	},
	{
		'fullname': 'Sao Tome and Principe',
		'BusinessUnitName': 'Africa',
		'id': 678,
		'name': 'ST'
	},
	{
		'fullname': 'Saudi Arabia',
		'BusinessUnitName': 'Middle East',
		'id': 682,
		'name': 'SA'
	},
	{
		'fullname': 'Senegal',
		'BusinessUnitName': 'Africa',
		'id': 686,
		'name': 'SN'
	},
	{
		'fullname': 'Serbia',
		'BusinessUnitName': 'Europe',
		'id': 688,
		'name': 'RS'
	},
	{
		'fullname': 'Serbia and Montenegro',
		'BusinessUnitName': 'Europe',
		'id': 891,
		'name': 'CS'
	},
	{
		'fullname': 'Seychelles',
		'BusinessUnitName': 'Africa',
		'id': 690,
		'name': 'SC'
	},
	{
		'fullname': 'Sierra Leone',
		'BusinessUnitName': 'Africa',
		'id': 694,
		'name': 'SL'
	},
	{
		'fullname': 'Singapore',
		'BusinessUnitName': 'Asia',
		'id': 702,
		'name': 'SG'
	},
	{
		'fullname': 'Sint Maarten',
		'BusinessUnitName': 'Latin America',
		'id': 534,
		'name': 'SX'
	},
	{
		'fullname': 'Slovakia',
		'BusinessUnitName': 'Europe',
		'id': 703,
		'name': 'SK'
	},
	{
		'fullname': 'Slovenia',
		'BusinessUnitName': 'Europe',
		'id': 705,
		'name': 'SI'
	},
	{
		'fullname': 'Solomon Islands',
		'BusinessUnitName': 'Asia',
		'id': 90,
		'name': 'SB'
	},
	{
		'fullname': 'Somalia',
		'BusinessUnitName': 'Africa',
		'id': 706,
		'name': 'SO'
	},
	{
		'fullname': 'South Africa',
		'BusinessUnitName': 'Africa',
		'id': 710,
		'name': 'ZA'
	},
	{
		'fullname': 'South Georgia and the South Sandwich Islands',
		'BusinessUnitName': 'Asia',
		'id': 239,
		'name': 'GS'
	},
	{
		'fullname': 'South Korea',
		'BusinessUnitName': 'Asia',
		'id': 410,
		'name': 'KR'
	},
	{
		'fullname': 'South Sudan',
		'BusinessUnitName': 'Africa',
		'id': 728,
		'name': 'SS'
	},
	{
		'fullname': 'Spain',
		'BusinessUnitName': 'Europe',
		'id': 724,
		'name': 'ES'
	},
	{
		'fullname': 'Sri Lanka',
		'BusinessUnitName': 'Asia',
		'id': 144,
		'name': 'LK'
	},
	{
		'fullname': 'Sudan',
		'BusinessUnitName': 'Africa',
		'id': 729,
		'name': 'SD'
	},
	{
		'fullname': 'Suriname',
		'BusinessUnitName': 'Latin America',
		'id': 740,
		'name': 'SR'
	},
	{
		'fullname': 'Svalbard and Jan Mayen',
		'BusinessUnitName': 'Europe',
		'id': 744,
		'name': 'SJ'
	},
	{
		'fullname': 'Swaziland',
		'BusinessUnitName': 'Africa',
		'id': 748,
		'name': 'SZ'
	},
	{
		'fullname': 'Sweden',
		'BusinessUnitName': 'Europe',
		'id': 752,
		'name': 'SE'
	},
	{
		'fullname': 'Switzerland',
		'BusinessUnitName': 'Europe',
		'id': 756,
		'name': 'CH'
	},
	{
		'fullname': 'Syria',
		'BusinessUnitName': 'Middle East',
		'id': 760,
		'name': 'SY'
	},
	{
		'fullname': 'Taiwan',
		'BusinessUnitName': 'Asia',
		'id': 158,
		'name': 'TW'
	},
	{
		'fullname': 'Tajikistan',
		'BusinessUnitName': 'Asia',
		'id': 762,
		'name': 'TJ'
	},
	{
		'fullname': 'Tanzania',
		'BusinessUnitName': 'Africa',
		'id': 834,
		'name': 'TZ'
	},
	{
		'fullname': 'Thailand',
		'BusinessUnitName': 'Asia',
		'id': 764,
		'name': 'TH'
	},
	{
		'fullname': 'Togo',
		'BusinessUnitName': 'Africa',
		'id': 768,
		'name': 'TG'
	},
	{
		'fullname': 'Tokelau',
		'BusinessUnitName': 'Asia',
		'id': 772,
		'name': 'TK'
	},
	{
		'fullname': 'Tonga',
		'BusinessUnitName': 'Asia',
		'id': 776,
		'name': 'TO'
	},
	{
		'fullname': 'Trinidad and Tobago',
		'BusinessUnitName': 'Latin America',
		'id': 780,
		'name': 'TT'
	},
	{
		'fullname': 'Tunisia',
		'BusinessUnitName': 'Africa',
		'id': 788,
		'name': 'TN'
	},
	{
		'fullname': 'Turkey',
		'BusinessUnitName': 'Middle East',
		'id': 792,
		'name': 'TR'
	},
	{
		'fullname': 'Turkmenistan',
		'BusinessUnitName': 'Asia',
		'id': 795,
		'name': 'TM'
	},
	{
		'fullname': 'Turks and Caicos Islands',
		'BusinessUnitName': 'Latin America',
		'id': 796,
		'name': 'TC'
	},
	{
		'fullname': 'Tuvalu',
		'BusinessUnitName': 'Asia',
		'id': 798,
		'name': 'TV'
	},
	{
		'fullname': 'U.S. Virgin Islands',
		'BusinessUnitName': 'Latin America',
		'id': 850,
		'name': 'VI'
	},
	{
		'fullname': 'Uganda',
		'BusinessUnitName': 'Africa',
		'id': 800,
		'name': 'UG'
	},
	{
		'fullname': 'Ukraine',
		'BusinessUnitName': 'Europe',
		'id': 804,
		'name': 'UA'
	},
	{
		'fullname': 'United Arab Emirates',
		'BusinessUnitName': 'Middle East',
		'id': 784,
		'name': 'AE'
	},
	{
		'fullname': 'United Kingdom',
		'BusinessUnitName': 'Europe',
		'id': 826,
		'name': 'GB'
	},
	{
		'fullname': 'United States',
		'BusinessUnitName': 'North America',
		'id': 840,
		'name': 'US'
	},
	{
		'fullname': 'United States Minor Outlying Islands',
		'BusinessUnitName': 'Asia',
		'id': 581,
		'name': 'UM'
	},
	{
		'fullname': 'Uruguay',
		'BusinessUnitName': 'Latin America',
		'id': 858,
		'name': 'UY'
	},
	{
		'fullname': 'Uzbekistan',
		'BusinessUnitName': 'Asia',
		'id': 860,
		'name': 'UZ'
	},
	{
		'fullname': 'Vanuatu',
		'BusinessUnitName': 'Asia',
		'id': 548,
		'name': 'VU'
	},
	{
		'fullname': 'Vatican',
		'BusinessUnitName': 'Europe',
		'id': 336,
		'name': 'VA'
	},
	{
		'fullname': 'Venezuela',
		'BusinessUnitName': 'Latin America',
		'id': 862,
		'name': 'VE'
	},
	{
		'fullname': 'Vietnam',
		'BusinessUnitName': 'Asia',
		'id': 704,
		'name': 'VN'
	},
	{
		'fullname': 'Wallis and Futuna',
		'BusinessUnitName': 'Asia',
		'id': 876,
		'name': 'WF'
	},
	{
		'fullname': 'Western Sahara',
		'BusinessUnitName': 'Africa',
		'id': 732,
		'name': 'EH'
	},
	{
		'fullname': 'Yemen',
		'BusinessUnitName': 'Middle East',
		'id': 887,
		'name': 'YE'
	},
	{
		'fullname': 'Zambia',
		'BusinessUnitName': 'Africa',
		'id': 894,
		'name': 'ZM'
	},
	{
		'fullname': 'Zimbabwe',
		'BusinessUnitName': 'Africa',
		'id': 716,
		'name': 'ZW'
	}
]
export default countryChoices