import Flag from 'react-flags'
export const CountryOptionRenderer = choice => {
	return (
		<span>
			{choice.fullname}
			<span className="flag">
				<Flag basePath="/img/flags"
					name={choice.name}
					format="svg"
					svgSize={24}
					shiny={false}
					width={24}
				/>
			</span>
		</span>
	)
}
export default CountryOptionRenderer