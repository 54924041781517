import {Layout} from 'react-admin'
import {MyMenu} from './myMenu'
import MyAppBar from './myAppBar'
import MySidebar from './mySidebar'
const NewLayout2 = (props) => <Layout
	{...props}
	menu={MyMenu}
	appBar={MyAppBar}
	sidebar={MySidebar}
/>
export default NewLayout2