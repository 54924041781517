const reportWebVitals = onPerfEntry => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('https://unpkg.com/web-vitals@4?module').then(({ onCLS, onINP, onFCP, onLCP, onTTFB }) => {
			onCLS(console.log)
			onINP(console.log)
			onLCP(console.log)
			onFCP(console.log)
			onTTFB(console.log)
		})
	}
}
export default reportWebVitals