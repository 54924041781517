import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from './components/makeStyles'
import { useTheme } from '@mui/material/styles'
import { NoActions } from './components/filterStyling'
import { List, TextField, Datagrid, SelectField, Pagination } from 'react-admin'
import AwaIcon2 from './components/ranks/awaIcon2'
import RanksFancyFlagField from './components/ranks/ranksFancyField'
import RanksFilter from './components/ranks/ranksFilter'
import RanksShowAll from './components/ranks/rankShow'
import RankContactFullName from './components/ranks/rankContactFullName'
const RanksPagination = props => <Pagination rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200, 500, 1000]} {...props} />
const useStyles = makeStyles({
	headerCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
	},
	rowCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
	rowCellIcon: {
	},
	Points: {
		maxWidth: '100px',
		float: 'left'
	},
	Badge: {
		maxWidth: '300px',
		float: 'left'
	},
	marginTop: {
		marginTop: '10px'
	},
	rowStyle: {
		'&:hover': {
			color: 'red'
		}
	},
	left: {
		textAlign: 'left'
	},
	flag: {
		paddingTop: '5px',
		padding: '5px',
		paddingLeft: '10px',
		verticalAlign: 'middle',
		textAlign: 'left',
		'& img': {
			verticalAlign: 'middle'
		}
	},
	icon: {
		width: '25px'
	}
})
export const RanksList = () => {
	const classes = useStyles()
	const theme = useTheme()
	const isXs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
	const isMed = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
	const isLrg = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true })
	const isXL = useMediaQuery(theme.breakpoints.down('xl'), { noSsr: true })
	if (RanksFilter){
		return (
			<List title={''} className={classes.marginTop} pagination={<RanksPagination/>}
				filters={<RanksFilter/>} sort={{field: 'Points', order: 'DESC'}} exporter={false} actions={<NoActions />} disableAuthentication emptyWhileLoading>
				{isXs ? (
					<Datagrid expand={<RanksShowAll />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<AwaIcon2 label="" cellClassName={classes.icon} headerClassName={classes.icon} />
						<RankContactFullName label="Athlete" cellClassName={classes.rowCellIcon} headerClassName={classes.headerCell} />
						<RanksFancyFlagField label="Country" cellClassName={classes.flag} headerClassName={classes.headerCell} />
						<TextField source="AgeGroupRank" label="Overall Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryAgeGroupRank" label="Country Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				): isSmall ? (
					<Datagrid expand={<RanksShowAll />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<AwaIcon2 label="" cellClassName={classes.icon} headerClassName={classes.icon} />
						<RankContactFullName label="Athlete" cellClassName={classes.rowCellIcon} headerClassName={classes.headerCell} />
						<RanksFancyFlagField label="Country" cellClassName={classes.flag} headerClassName={classes.headerCell} />
						<TextField source="AgeGroupRank" label="Overall Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryAgeGroupRank" label="Country Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				) : isMed ? (
					<Datagrid expand={<RanksShowAll />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<AwaIcon2 label="" cellClassName={classes.icon} headerClassName={classes.icon} />
						<RankContactFullName label="Athlete" cellClassName={classes.rowCellIcon} headerClassName={classes.headerCell} />
						<TextField source="AgeGroupRank" label="Overall Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryAgeGroupRank" label="Country Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<RanksFancyFlagField label="Country" cellClassName={classes.flag} headerClassName={classes.headerCell} />
						<TextField source="AgeGroup" label="Category" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="TimeConverted" label="Overall Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Points" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				) : isLrg ? (
					<Datagrid expand={<RanksShowAll />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<AwaIcon2 label="" cellClassName={classes.icon} headerClassName={classes.icon} />
						<RankContactFullName label="Athlete" cellClassName={classes.rowCellIcon} headerClassName={classes.headerCell} />
						<TextField source="AgeGroupRank" label="Overall Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryAgeGroupRank" label="Country Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<RanksFancyFlagField label="Country" cellClassName={classes.flag} headerClassName={classes.headerCell} />
						<SelectField source="Gender" label="Gender" choices={[
							{ id: 'M', name: 'Male' },
							{ id: 'F', name: 'Female' },
						]} sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="AgeGroup" label="Category" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="TimeConverted" label="Overall Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Points" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				) : isXL ? (
					<Datagrid expand={<RanksShowAll />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<AwaIcon2 label="" cellClassName={classes.icon} headerClassName={classes.icon} />
						<RankContactFullName label="Athlete" cellClassName={classes.rowCellIcon} headerClassName={classes.headerCell} />
						<TextField source="AgeGroupRank" label="Overall Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryAgeGroupRank" label="Country Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<RanksFancyFlagField label="Country" cellClassName={classes.flag} headerClassName={classes.headerCell} />
						<SelectField source="Gender" label="Gender" choices={[
							{ id: 'M', name: 'Male' },
							{ id: 'F', name: 'Female' },
						]} sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="AgeGroup" label="Category" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="TimeConverted" label="Overall Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Points" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				) : (
					<Datagrid expand={<RanksShowAll />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<AwaIcon2 label="" cellClassName={classes.icon} headerClassName={classes.icon} />
						<RankContactFullName label="Athlete" cellClassName={classes.rowCellIcon} headerClassName={classes.headerCell} />
						<TextField source="AgeGroupRank" label="Overall Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="CountryAgeGroupRank" label="Country Position" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<RanksFancyFlagField label="Country" cellClassName={classes.flag} headerClassName={classes.headerCell} />
						<SelectField source="Gender" label="Gender" choices={[
							{ id: 'M', name: 'Male' },
							{ id: 'F', name: 'Female' },
						]} sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="AgeGroup" label="Category" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="TimeConverted" label="Overall Time" sortable={false} cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
						<TextField source="Points" label="Points" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					</Datagrid>
				)}
			</List >
		)
	}
}
export default RanksList