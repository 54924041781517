import makeStyles from './../makeStyles'
import { divisionChoices } from '../divisionChoices'
import { NoActions } from '../filterStyling'
import {List, Datagrid, TextField, NumberField, SelectField} from 'react-admin'
import ClubPointsFilter from './clubPointsFilter'
import ContactRankingsShowAll2 from './contactRankingsShowAll2'
import FancyFlagField2 from './fancyFlag2'
const useStyles = makeStyles({
	centerTop: {
		maxWidth: '25%',
		minWidth: '25%',
		flexBasis: '25%',
		width: '100%',
		textAlign: 'center !important;',
		'&:hover': {
			color: '#e31837'
		}
	}
})
export const ClubPointsList2 = () => {
	const classes = useStyles()
	return (
		<List filters={<ClubPointsFilter />} sort={{ field: 'Points', order: 'DESC' }} title=" " perPage={10} exporter={false} actions={<NoActions />} emptyWhileLoading disableAuthentication>
			<Datagrid expand={<ContactRankingsShowAll2 />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
				<TextField source="Club.name" label="Club Name" cellClassName={classes.centerTop} headerClassName={classes.centerTop} />
				<FancyFlagField2 label="Country" cellClassName={classes.centerTop} headerClassName={classes.centerTop} />
				<SelectField source="Division" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Division" choices={divisionChoices} />
				<NumberField source="Points" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Season Points" />
			</Datagrid>
		</List>
	)
}
export default ClubPointsList2