import feathers from '@feathersjs/client'
import axios from 'axios'
export const options = {
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': process.env.REACT_APP_URL,
		'WTC_PRIV_KEY': process.env.REACT_APP_KEY,
		'Content-Encoding': '*',
		'env': process.env.REACT_APP_ENV
	}
}
export const host = process.env.REACT_APP_API_URL
export const instance = axios.create({
	baseURL: host,
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': process.env.REACT_APP_URL,
		'WTC_PRIV_KEY': process.env.REACT_APP_KEY,
		'Content-Encoding': '*',
		'env': process.env.REACT_APP_ENV
	},
})
export default feathers().configure(feathers.rest(host).axios(instance))
