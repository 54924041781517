import {Show, SimpleShowLayout} from 'react-admin'
import ClubShowNoTabsActions from '../../components/clubs/clubShowNoTabsActions'
import ClubsCustomShowView from '../../components/clubs/clubsCustomShowView'
export const ClubsShowNoTabs2 = (props) => {
	return (
		<Show {...props} title=" " actions={<ClubShowNoTabsActions />} component="div">
			<SimpleShowLayout>
				<ClubsCustomShowView />
			</SimpleShowLayout >
		</Show>
	)
}
export default ClubsShowNoTabs2
