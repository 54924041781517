import makeStyles from './../makeStyles'
import moment from './../moment'
import { CardMedia } from '@mui/material'
import { useRecordContext } from 'react-admin'
const currentYear = moment().year()
const UseStyles = makeStyles({
	Badge: {
		display: 'inline-block',
		float: 'left',
		height: '75px',
		width: '75px',
		maxWidth: '75px',
		margin: '-19px 25px 0 25px'
	}
})
export const AwaBadge = () => {
	const record = useRecordContext()
	const classes = UseStyles()
	const badgeYear = record.Year + 1
	const imageLink = 'https://assets.competitor.com/badges/awa/' + badgeYear + record.AWAStatus + '.png'
	return (
		record && record.AWAStatus && record.Year && record.Year !== currentYear
			? (
				<CardMedia
					image={imageLink}
					title="IRONMAN TriCLub Program"
					component="img"
					className={classes.Badge}
				/>
			) : ('')
	)
}
export default AwaBadge
