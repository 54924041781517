import {List, TextField, Datagrid, NumberField, DateField} from 'react-admin'
import ClubPointsDetailsFilter from './components/clubpoints/clubPointsDetailsFilter'
export const ClubPointsDetailsList = (props) => (
	<List {...props} filters={<ClubPointsDetailsFilter />} title=" "  exporter={false} disableAuthentication emptyWhileLoading>
		<Datagrid optimized bulkActionButtons={false}>
			<TextField source="clubpointsDetailId" label="clubpointsDetailId"/>
			<DateField source="Date" label="Date" showTime/>
			<TextField source="Description" label="Description"/>
			<NumberField source="Points" label="Points"/>
			<TextField source="ClubPointsID" label="ClubPointsID"/>
			<TextField source="Subevent.SubEvent" label="Event"/>
			<TextField source="Contact.FullName" label="Event"/>
		</Datagrid>
	</List>
)
export default ClubPointsDetailsList
