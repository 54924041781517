import { makeStyles } from '@mui/styles'
export const useFilterStyles = makeStyles({
	filterOneLine: {
		width: '100%',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		marginTop: '15px'
	}
})
export const NoActions = (props) => (
	<p></p>
)