import makeStyles from '../makeStyles'
import { useState, useEffect } from 'react'
import { TextField, Datagrid, NumberField, ReferenceManyField, useRecordContext, useDataProvider } from 'react-admin'
import ContactResultCard from './contactResultCard'
const useStyles = makeStyles({
	center: {
		// maxWidth: '33%',
		// minWidth: '33%',
		// flexBasis: '33%',
		width: '100%',
		textAlign: 'center !important',
		'&:hover': {
			color: '#e31837'
		}
	},
	sortButton: {
		marginTop: '20px'
	}
})
export const ContactRankingsShowAll4 = props => {
	const [event, setEvent] = useState()
	const record = useRecordContext()
	const dataProvider = useDataProvider()
	const ClubPointsID = record.clubpointsId
	const subeventId = window.location.pathname.split('/').pop()
	const classes = useStyles()
	const limit = 0
	useEffect(() => {
		dataProvider.getOne('events', { id: subeventId })
			.then(({ data }) => {
				setEvent(data)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])
	let cutOff = ''
	if (event){
		console.log(event)
		console.log(event)
		console.log(event?.Date)
		let d = new Date(event?.Date)
		// d = d.toISOString().split('T')[0]
		let x = 4
		if(event?.BusinessUnit === 'Europe' || event?.BusinessUnit === 'Africa' || event?.BusinessUnit === 'Middle East') x = 0
		d.setDate(d.getDate() - x)
		console.log(d.toISOString().split('T')[0])
		cutOff = d.toISOString().split('T')[0]
		console.log(cutOff)
		// console.log(event?.Date)
		console.log(record)
		return (
			<>
				<ReferenceManyField label="Details" reference="clubpointsdetail" target="ClubPointsID" id="clubpointsId" perPage={limit} sort={{ field: 'Points', order: 'DESC' }} filter={{ SubEventID: subeventId, ClubPointsID: ClubPointsID, 'Points[$gt]': 0, '$StartDate': cutOff,  $limit: 0 }}>
					<Datagrid expand={<ContactResultCard />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
						<TextField source="Contact.FullName" label="Athlete" cellClassName={classes.center} headerClassName={classes.center} />
						<NumberField source="Points" label="Points" cellClassName={classes.center} headerClassName={classes.center} />
					</Datagrid>
				</ReferenceManyField>
			</>
		)
	}
}
export default ContactRankingsShowAll4