import {Link} from '@mui/material'
import { useRecordContext } from 'react-admin'
export const EventsResultsLink2 = () => {
	const record = useRecordContext()
	if (record && record.Alias){
		return <Link target="_blank" rel="noopener noreferrer" href={record ? `/result/subevent/${record.SubEventId}` : ''}>
			Results
		</Link>
	}else {
		return <p></p>
	}
}
export default EventsResultsLink2