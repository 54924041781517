import makeStyles from './../makeStyles'
import image from '../../../assets/img/logos/ClubLogoComingSoon.png'
import { Card, Typography, Link, Button, Box, Grid, CardMedia, CardContent, Tooltip, IconButton, List, ListItem , ListItemText} from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Help from '@mui/icons-material/Help'
import { useRecordContext, useResourceContext } from 'react-admin'
const useStyles = makeStyles({
	cardDark: {
		backgroundColor: '#eeeeee',
		maxWidth: 500,
		minWidth: 150,
		width: '98%'
	},
	clubSubTitle: {
		verticalAlign: 'middle',
		marginTop: '22px',
		textAlign: 'right',
		display: 'block'
	},
	editLink: {
		textDecoration: 'underline',
		color: 'inherit',
		padding: '0',
		'&:hover': {
			backgroundColor: 'unset',
			textDecoration: 'underline',
			color: '#e31837'
		}
	},
	editLinkToolTip: {
		textDecoration: 'underline',
		color: 'inherit',
		'&:hover': {
			backgroundColor: 'unset',
			textDecoration: 'underline',
			color: '#e31837'
		}
	},
	negativeTopMargin: {
		marginTop: '-40px',
		maxWidth: '1200px',
		margin: '0 auto'
	},
	clubImage: {
		height: '200px',
		objectFit: 'scale-down',
		width: 'auto',
		maxWidth: '200px'
	},
	vertAlign: {
		lineHeight: '2.75'
	},
	inlineFlex: {
		display: 'inline-flex',
		verticalAlign: 'middle'
	},
	wide: {
		width: '100%',
		paddingTop: '8px',
		paddingBottom: '8px'
	}
})
export const ClubsCustomShowView = () => {
	const record = useRecordContext()
	const resource = useResourceContext()
	const classes = useStyles()
	const editLink = '/' + resource + '/' + record.id + '/edit'
	const listLink = '/' + resource
	const showLink = window.location.href
	let specialties = ''
	if (record && record.ClubSpecialty) {
		{specialties = record.ClubSpecialty.split(';').reduce((all, cur) => [
			...all,
			<ListItem
				key={cur}
				disableGutters
			>
				<ListItemText primary={`${cur}`} />
			</ListItem>
		])}
	}
	return (
		<Grid container className={classes.negativeTopMargin}>
			<Grid xs={12} sm={12} md={6} container item direction="row" justify="space-around" alignItems="center" spacing={0}>
				<Box pr={1} pl={1} pt={0} pb={0} mr={0} mt={0} mb={0} ml={0} width="100%">
					<Box pt={2} pb={2} mr={1} mt={1} mb={1} ml={-1}>
						<Box display="flex" justify="flex-start" mb={1} ml={-1}>
							<Button disableElevation disableRipple disableFocusRipple variant="text" className={classes.editLink} href={listLink}>
								Club Finder
								<ChevronRight />
							</Button>
							<Button disableElevation disableRipple disableFocusRipple className={classes.editLink} href={showLink} color="inherit">
								{record.name}
							</Button>
						</Box>
						<Box display="flex">
							<Typography variant="h4" gutterBottom>{record.name}</Typography>
						</Box>
					</Box>
					<Grid xs={6} sm={6} md={6} container zeroMinWidth item className={classes.inlineFlex} spacing={1} direction="row">
						<Box justifyContent="center">
							{record.ClubGraphicUrl ? (
								<CardMedia
									component="img"
									image={record.ClubGraphicUrl}
									title={record.name}
									className={classes.clubImage}
								/>
							) : <CardMedia
								component="img"
								image={image}
								title={record.name}
								className={classes.clubImage}
							/>}
						</Box>
					</Grid>
					<Box pt={2} pb={2} mr={1} mt={1} mb={1} ml={-1}>
						<Typography className={classes.wide} variant="h6" gutterBottom>{record.City ? `${record.City}, ` : ''} {record.StateorProvince ? `${record.StateorProvince}` : ''} {record.PostalCode ? `${record.PostalCode}` : ''} {record.CountryISO2 && record.CountryISO2 !== 'ZZ' ? `${record.Countries.ISO3}` : ''}</Typography>
					</Box>
					<Box pt={2} pb={2} mr={1} mt={1} mb={1} ml={-1}>
						{record && record.ClubDescription ? (
							<Typography variant="h6" className={classes.wide} gutterBottom>About our club</Typography>
						) : ''
						}
						{record && record.ClubDescription ? (
							<Typography gutterBottom className={classes.wide} variant="body1" paragraph={true}>{record.ClubDescription}</Typography>
						) : ''
						}
					</Box>
					<Box pt={2} pb={2} mr={1} mt={1} mb={1} ml={-1}>
						{record && record.ClubSpecialty ? (
							<Typography variant="h6" className={classes.wide} gutterBottom>Club Specialties</Typography>
						) : ''
						}
						{record && record.ClubSpecialty && specialties ? (
							<List className={classes.wide} variant="body1">{specialties}</List>
						) : ''
						}
					</Box>
				</Box>
			</Grid>
			<Grid xs={12} sm={12} md={6} container item direction="row" justify="space-around" alignItems="flex-start" spacing={0}>
				<Box display="flex" alignItems="center" width="100%">
					<Card className={classes.cardDark} elevation={0} component="div" variant="outlined">
						<Box pr={2} pl={2} pt={2} pb={2} mr={1} mt={1} mb={1} ml={1}>
							<CardContent>
								<Typography variant="h5" gutterBottom>Contact Information</Typography>
								{record.Contact && record.Contact.FullName ? (
									<Typography gutterBottom>{record.Contact.FullName}</Typography>
								) : ''}
								{record.Website && record.Website.includes('http') ? (<Typography gutterBottom> <Link color="inherit" underline="always" target="_blank" href={record.Website}>
									{record.Website}
								</Link></Typography>
								) : record.Website && !record.Website.includes('http') ? (
									<Typography gutterBottom> <Link color="inherit" underline="always" target="_blank" href={'https://' + record.Website}>
										{'https://' + record.Website}
									</Link></Typography>
								): ''}
							</CardContent>
						</Box>
					</Card>
				</Box>
				<Box display="flex" alignItems="center" width="100%" alignSelf="flex-start">
					<Typography>
						<Button label="Manage This Club" disableElevation className={classes.editLink} href={editLink} >
                            Manage This Club
						</Button>
						<Tooltip arrow className={classes.editLinkToolTip} title="Are you the club leader or member? Verify your info to make edits to this page" aria-label="Are you the club leader or memeber? Verify your info to make edits to this page" disableTouchListener enterDelay={0} disableFocusListener>
							<IconButton href={editLink}><Help /></IconButton>
						</Tooltip>
					</Typography>
				</Box>
			</Grid>
		</Grid>
	)
}
export default ClubsCustomShowView