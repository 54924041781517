import makeStyles from './../makeStyles'
import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'
import {Grid, Box} from '@mui/material'
const useStyles = makeStyles({
	fullWidthToolBar: {
		width: '100%',
		position: 'relative',
		marginTop: '0',
		minHeight: 'unset'
	},
	capchaBox: {
		width: '100%',
	},
	editSaveButton: {
		margin: '0',
	},
	buttonBox: {
		width: '100%',
		marginTop: 20
	},
	toolGrid: {
		position: 'absolute',
		right: 0,
		padding: 24,
		marginTop: 140,
		marginBottom: 30
	}
})
export const ClubEditToolbar = props => {
	const [disableSubmit, setDisableSubmit] = React.useState(true)
	const classes = useStyles()
	return (
		<Toolbar {...props} className={classes.fullWidthToolBar}>
			<Grid container item className={classes.toolGrid} xs={12} sm={12} md={6} direction="column" alignItems="flex-end" spacing={0}>
				<Box className={classes.capchaBox}>
					<ReCAPTCHA
						sitekey='6Lc8_GUUAAAAAIF0ecNRakqISKuspsmpbMEA7UuY'
						onChange={React.useCallback(() => setDisableSubmit(false))}
					/>
				</Box>
				<Box className={classes.buttonBox}>
					<SaveButton className={classes.editSaveButton} disabled={disableSubmit} label="Submit" size="large" />
				</Box>
			</Grid>
		</Toolbar>
	)
}
export default ClubEditToolbar