import makeStyles from '../makeStyles'
import './clubpoints.css'
import { useState, useEffect } from 'react'
import { useDataProvider, downloadCSV, ExportButton, List, Datagrid, TextField, SearchInput, Filter, NumberField } from 'react-admin'
import jsonExport from 'jsonexport/dist'
import {useMediaQuery, Typography, Box} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import FancyFlagPodium from './fancyFlagPodium'
import { countryChoices } from '../countryChoices'
import ContactRankingsShowAll4 from './contactRankingsShowAll4'
import PodiumBadge from './podiumBadge'
const useStyles = makeStyles({
	centerTop: {
		width: '100%',
		textAlign: 'center !important',
		'&:hover': {
			color: '#e31837'
		}
	},
	centerTopClubName: {
		width: '100%',
		textAlign: 'center !important',
		'&:hover': {
			color: '#e31837'
		},
		minWidth:150
	},
	centerTopNoPadding: {
		width: 'auto',
		textAlign: 'left !important',
		// paddingRight: '0 !important',
		// paddingLeft: '0 !important',
		// maxWidth: 40,
		'&:hover': {
			color: '#e31837'
		}
	},
	clubNameMobile: {
		textAlign: 'center !important',
		width: '100%',
		padding: '5px 25px !important',
		'&:hover': {
			color: '#e31837'
		},
		minWidth: 100
	},
	whiteBackground: {
		backgroundColor: 'white'
	}
})
const exporter = clubpoints => {
	const filename = clubpoints[0].SubEventName.replaceAll(' ', '-') + '-Triclub-Podium-Awards'
	const clubpointsForExport = clubpoints.map(clubpoint => {
		console.log(clubpoint)
		if(clubpoint.Country){
			clubpoint.Country = countryChoices.find(item => item.id === clubpoint.Country)
			clubpoint.Country = clubpoint.Country.fullname
		}
		clubpoint.Rank = parseInt(clubpoint.Rank)
		clubpoint.Points = parseInt(clubpoint.Points)
		const { SubEventName, BusinessUnit, clubpointsId, id, ...clubpointsForExport } = clubpoint
		return clubpointsForExport
	})
	jsonExport(clubpointsForExport, {
		headers: ['Rank', 'Club', 'Points'] // order fields in the export
	}, (err, csv) => {
		downloadCSV(csv, filename) // download as 'posts.csv` file
	})
}
const CustomListActions = () => (
	<ExportButton label='Export'/>
)
const Empty = () => (
	<Box textAlign="center" sx={{margin: '0 auto'}}>
		<Typography variant="h6" paragraph>
			No Results Yet.
		</Typography>
		<Typography variant="body1">
		Please allow 24 hours post-race for AG points to push through to the TriClub Podium results.
		</Typography>
	</Box>
)
export const ClubPointsList4 = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const dataProvider = useDataProvider()
	const [event, setEvent] = useState()
	// const [loading, setLoading] = useState(true)
	// const [error, setError] = useState()
	const subeventId = window.location.pathname.split('/').pop()
	let title = ' '
	const CustomFilter = () => (
		<Filter>
			<SearchInput source="$filter" alwaysOn variant="outlined" />
		</Filter>
	)
	useEffect(() => {
		dataProvider.getOne('events', { id: subeventId })
			.then(({ data }) => {
				setEvent(data)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])
	if (event && event.SubEvent){
		title = event.SubEvent + ' Podium'
	}
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'), {ssr: false})
	return (
		<List
			sx={{ '#react-admin-title': { fontSize: '1rem' } }}
			filters={<CustomFilter/>}
			actions={< CustomListActions/>}
			pagination={false}
			className={classes.whiteBackground}
			title={title}
			perPage={1000}
			exporter={exporter}
			empty={<Empty />}
			disableAuthentication
		>
			{ isSmall ? (
				<Datagrid expand={<ContactRankingsShowAll4 />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
					{ window.location.href.indexOf('%24filter') < 1 && <NumberField source="Rank" cellClassName={classes.centerTopNoPadding} headerClassName={classes.centerTopNoPadding} label="Overall Rank" sortable={false} /> }
					<TextField source="Club" label="Club Name" cellClassName={classes.clubNameMobile} headerClassName={classes.clubNameMobile} sortable={false} />
					<PodiumBadge label="Digital Badge" cellClassName={classes.centerTopBadge} headerClassName={classes.centerTopBadge} sortable={false} />
					<NumberField source="Points" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Total Points" sortable={false} />
				</Datagrid>
			): (
				<Datagrid expand={<ContactRankingsShowAll4 />} rowClick="expand" optimized bulkActionButtons={false} expandSingle>
					{ window.location.href.indexOf('%24filter') < 1 && <NumberField source="Rank" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Overall Rank"  sortable={false}/> }
					<TextField source="Club" label="Club Name" cellClassName={classes.centerTop} headerClassName={classes.centerTop} sortable={false} />
					<FancyFlagPodium label="Country" cellClassName={classes.centerTop} headerClassName={classes.centerTop} sortable={false} />
					<TextField source="Division" label="Division" cellClassName={classes.centerTop} headerClassName={classes.centerTop} sortable={false} />
					<PodiumBadge label="Badge" cellClassName={classes.centerTop} headerClassName={classes.centerTop} sortable={false} />
					<NumberField source="Points" cellClassName={classes.centerTop} headerClassName={classes.centerTop} label="Total Points" sortable={false} />
				</Datagrid>
			)}
		</List>
	)
}
export default ClubPointsList4