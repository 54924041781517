import {
	required,
	minValue,
	number,
	SimpleForm, useNotify, useRedirect, maxLength, FormDataConsumer, TextInput, ImageInput, ImageField, CheckboxGroupInput, BooleanInput, SelectInput, NumberInput, Edit, useResourceContext
} from 'react-admin'
import makeStyles from './../makeStyles'
import { countryChoices } from '../countryChoices'
import { CountryOptionRenderer } from '../countryOptionRenderer'
import { Button, Box, Grid, Alert } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ClubEditActionsToolBar from './clubEditActionsToolBar'
import ClubEditToolbar from './clubEditToolbar'
const useStyles = makeStyles({
	inlineBlock: { display: 'inline-block', marginRight: '1rem', textAlign: 'center', verticalAlign: 'middle', width: '100%' },
	hidden: { display: 'none' },
	formFullWidth3: {
		width: '100%',
		marginTop: '0',
		zIndex: '99999'
	},
	floatLeft: {
		float: 'left',
		width: '100%'
	},
	centerTop: {
		textAlign: 'center',
		width: 'auto',
		margin: '0 auto'
	},
	actions: {
		header: {
			display: 'none'
		}
	}
})
export const ClubEditTabbed = props => {
	const path = window.location.pathname.split('/')
	const recordId = path.reverse()[1]
	// const record = useShowContext(recordId)
	// console.log(record)
	// const { data, isLoading, error } = useGetOne('clubs', { id: recordId })
	// if (!data) return null
	const resource = useResourceContext()
	const classes = useStyles()
	const notify = useNotify()
	const redirect = useRedirect()
	const back = '/' + resource + '/' + recordId + '/show'
	const onSuccess = ({ data }) => {
		notify('A notification email has been sent to the club’s primary contact for approval. If you are the primary contact, check your email for requested changes approval. Approved changes can take up to 72 hours to reflect on the website.')
		redirect('list', '/'+ resource)
	}
	const onFailure = (error) => {
		notify(`Could not edit post: ${error.message}`)
	}
	return (
		<Edit title=" " component="div" mutationMode="pessimistic" actions={<ClubEditActionsToolBar />} mutationOptions={{ onSuccess: onSuccess, onError: onFailure }} disableAuthentication>
			<SimpleForm variant="outlined" redirect="false" margin="normal" className={classes.formFullWidth3} toolbar={<ClubEditToolbar />}>
				<Grid xs={12} sm={12} md={12} container item direction="row" justify="space-around" alignItems="flex-start" spacing={0} className={classes.floatLeft}>
					<Box pr={2} pl={2} pb={2} mr={1} mb={1} ml={1} width="100%">
						<Box mb={2} mt={-4} width="100%">
							<Button href={back} icon={ChevronLeft} color="primary">
								<ChevronLeft />
								Back
							</Button>
						</Box>
						<Alert variant="outlined" severity="info" className={classes.centerTop}>
							Note: Once changes are submitted, an email will be sent to the Club leader for approval. Changes must be approved within 72 hours from submission.</Alert>
					</Box>
					{/* </Grid> */}
					<Grid xs={12} sm={12} md={6} lg={6} container item direction="row" justify="space-around" alignItems="flex-start" spacing={0} className={classes.floatLeft}>
						<Box pr={2} pl={2} pt={2} pb={2} mr={1} mt={1} mb={1} ml={1} width="100%" >
							<TextInput disabled source="clubId" label="clubId" className={classes.hidden} resettable margin="normal" variant="outlined" fullWidth />
							<TextInput source="name" label="Club Name" formClassName={classes.inlineBlock} resettable validate={required()} margin="normal" variant="outlined" fullWidth />
							<TextInput source="Address1" label="Address 1" formClassName={classes.inlineBlock} resettable margin="normal" variant="outlined" validate={required()} />
							<TextInput source="City" label="City" formClassName={classes.inlineBlock} resettable validate={required()} margin="normal" variant="outlined" />
							<TextInput source="PostalCode" label="PostalCode" formClassName={classes.inlineBlock} resettable validate={required()} margin="normal" variant="outlined" />
							<TextInput source="StateorProvince" label="State/Province" formClassName={classes.inlineBlock} resettable margin="normal" variant="outlined" />
							<SelectInput source="Country" label="Country" choices={countryChoices} optionText={CountryOptionRenderer} formClassName={classes.inlineBlock} resettable margin="normal" variant="outlined" translateChoice={false} />
							<FormDataConsumer>
								{({ formData, ...rest }) => formData.Country && formData.Country === 840 &&
                                <BooleanInput variant="outlined" margin="normal" source="isNational" label="Is your Club National?" {...rest} />
								}
							</FormDataConsumer>
						</Box>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6} container item direction="row" justify="space-around" alignItems="flex-start" spacing={0} className={classes.floatLeft}>
						<Box pr={2} pl={2} pt={2} pb={2} mr={1} mt={1} mb={1} ml={1} width="100%">
							<TextInput source="Website" label="Website" formClassName={classes.inlineBlock} resettable margin="normal" variant="outlined" validate={required()}  />
							<NumberInput label="Number of Club Members" source="members" validate={[minValue(1), number('Must be an Integer')]} resource="club" variant="outlined" margin="normal" />
							<CheckboxGroupInput label="Does your club specialize in a type of athlete?" helperText="Check all that apply" source="ClubSpecialties" choices={[
								{ id: 957700000, name: 'Age Group Athletes' },
								{ id: 957700001, name: 'Elite Athletes' },
								{ id: 957700002, name: 'Women\'s Programs' },
								{ id: 957700003, name: 'First Timer Programs' },
							]} fullWidth variant="outlined" margin="normal" translateChoice={false} />
							<ImageInput source="pictures" label="Club Logo" accept="image/*" multiple={true} variant="outlined" margin="normal" labelMultiple="Upload logo here: 500 x 500 PNG or JPG work best">
								<ImageField source="src" title="title" variant="outlined" margin="normal" />
							</ImageInput>
							<TextInput source="ClubDescription" label="Club Description" formClassName={classes.inlineBlock} resettable margin="normal" variant="outlined" multiline helperText="Optional – info to appear on public facing club finder. Max 255 characters" fullWidth validate={maxLength(255, 'Maximum Length of 255 Characters')} />
						</Box>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	)
}
export default ClubEditTabbed