import { Component } from 'react'
import '../../styles/cardShow.css'
import LoadingButton from '@mui/lab/LoadingButton'
import { options, host, instance } from '../../../feathersClient'
import countries from 'i18n-iso-countries'
import Flag from 'react-flags'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
const createCert2 = (e, ResultId,loading) => {
	loading = true
	let resultid = ResultId
	let createCertURL = host + '/result/' + resultid + '/cert/true'
	return instance.get(createCertURL, options)
		.then((resp)=>resp).then( async (respJSON)=>{
			if(respJSON){
				console.log('Generating Cert...')
				let getCertURL = respJSON.data.pdf
				const response = await fetch(createCertURL, options)
				if(response.status === 204 || response.status === 201 || response.status === 200){
				  const result = await response.json()
				  if(result && result.pdf){
					let certRes = ''
					do {
					  certRes = await fetch(getCertURL)
					}
					while (certRes.status !== 200)
					loading = false
					window.open(getCertURL, '_blank')
					// openCert2(getCertURL, 3, certNewWindow)
				  }
				}
			}
		}
		)
}
class CardShowRun extends Component{
	constructor(props) {
		super(props)
		this.state = { countryCode: this.props.record.CountryISO2, countrySet: false,loading: false }
		this.toggleDetails = this.toggleDetails.bind(this)
	}
	componentDidMount() {
		let countryCodeA2 = this.props.record.CountryISO2
		if(countries.isValid(countryCodeA2)){
			this.setState(
				{countryCode: countryCodeA2,
					countrySet: true
				}
			)
		}
	}
	toggleDetails(e){
		e.preventDefault()
		let toggle = function (elem) {
			elem.classList.toggle('is-visible')
		}
		let parentRow = e.target.parentNode.parentNode
		let content = parentRow.querySelector(e.target.hash)
		e.target.parentElement.classList.toggle('toggled')
		if (!content) return
		toggle(content)
	}
	render(){
		let EventStatus = this.props.record.EventStatus
		const ResultId = this.props.record.ResultId
		const BibNumber = this.props.record.BibNumber
		const AgeGroup = this.props.record.AgeGroup
		const Country = this.state.countryCode
		const RankPoints = this.props.record.RankPoints
		const FinishRankGender = this.props.record.FinishRankGender
		const FinishRankGroup = this.props.record.FinishRankGroup
		const FinishRankOverall = this.props.record.FinishRankOverall
		const RunRankGender = this.props.record.RunRankGender
		const RunRankGroup = this.props.record.RunRankGroup
		const RunRankOverall = this.props.record.RunRankOverall
		const RunTime = this.props.record.RunTimeConverted
		if (EventStatus === 'Finish') EventStatus = 'Finisher'
		if (EventStatus === 'NC') EventStatus = 'Not Classified'
		return (
			<div className="resultContainer runResultContainer">
				{EventStatus === 'Finisher' && AgeGroup !== 'ODIV' ? (
					<div className={'rankSum tableWrap'}>
						<div className="wrapper">
							<div className="text"><p className="rankNum">{FinishRankGroup}</p><p className="rankText">Div Rank</p></div>
							<div className="text"><p className="rankNum">{FinishRankGender}</p><p className="rankText">Gender Rank</p></div>
							<div className="text"><p className="rankNum">{FinishRankOverall}</p><p className="rankText">Overall Rank</p></div>
							<div className="text"><p className="rankNum">{EventStatus}</p><p className="rankText">Designation</p></div>
						</div>
					</div>
				) : ('')}
				{AgeGroup === 'ODIV' || EventStatus !== 'Finisher' ? (
					<div className={'rankSum tableWrap'}>
						<div className="wrapper">
							<div className="text"><p className="rankNum">{EventStatus}</p><p className="rankText">Designation</p></div>
						</div>
					</div>
				) : ('')}
				{AgeGroup.includes('PC') ? (
					<div className={'rankSum tableWrap'}>
						<div className="wrapper">
							<div className="text"><p className="rankNum">{FinishRankGroup}</p><p className="rankText">Div Rank</p></div>
							<div className="text"><p className="rankNum">{EventStatus}</p><p className="rankText">Designation</p></div>
						</div>
					</div>
				) : ('')}
				<div className={'genInfo tableWrap'}>
					<h3>General Info</h3>
					<div className="table">
						<div className={'tableRow tableHeader'}>
							<div className="wrapper">
								<div className="text">Bib</div>
								<div className="text">Division</div>
								<div className="text">Country</div>
								<div className="text">Points</div>
							</div>
						</div>
						<div className={'tableRow tableFooter'}>
							<div className="wrapper">
								<div className="text">{BibNumber}</div>
								<div className="text">{AgeGroup}</div>
								<div className={'text countryFlag'}>
									{this.state.countrySet ?  (<Flag basePath="/img/flags"
										name={Country}
										format="svg"
										svgSize={48}
										shiny={false}
										width={48}
									/>) : ('')}
								</div>
								<div className="text">{RankPoints}</div>
							</div>
						</div>
					</div>
				</div>
				<div id={this.props.record.id} className={'raceSum tableWrap'}>
					<div className="summaryHeaderCont">
						<h3>Race Summary</h3>
						{EventStatus === 'Finisher' ? (
							<LoadingButton loading={this.state.loading} sx={{mr:.5}} startIcon={<PictureAsPdfOutlinedIcon />} variant='text' onClick={(e) => createCert2(e,ResultId,this.state.loading)} target="_blank">Get Certificate</LoadingButton>
						) : ('')}
					</div>
				</div>
				<div className="summaryRow">
					<p className="summaryHeading">Run</p>
					<p className="summaryTime">{RunTime}</p>
					<a href="#runDetails" className={'toggle detailsButton'} onClick={this.toggleDetails}></a>
				</div>
				<div id="runDetails" className={'toggleContent detailsRow'}>
					<h4>Run Details | Division Rank:  {RunRankGroup}</h4>
					<div className="table">
						<div className={'tableRow tableHeader'}>
							<div className="wrapper">
								<div className="text">Race Time</div>
								<div className="text">Division Rank</div>
								<div className="text">Gender Rank</div>
								<div className="text">Overall Rank</div>
							</div>
						</div>
						<div className={'tableRow tableFooter'}>
							<div className="wrapper">
								<div className="text">{RunTime}</div>
								<div className="text">{RunRankGroup}</div>
								<div className="text">{RunRankGender}</div>
								<div className="text">{RunRankOverall}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default CardShowRun
