import { makeStyles } from '@mui/styles'
import { useRecordContext } from 'react-admin'
import { Typography } from '@mui/material'
const useStyles = makeStyles({
	gold: {
		color: '#e0ad1f',
		backgroundColor: '#e0ad1f',
		verticalAlign: 'middle',
		float: 'left',
		filter: 'alpha(opacity = 50)',
		opacity: '.5',
		maxWidth: '12px',
		maxHeight: '12px',
		marginTop: '10px',
		marginRight: '10px',
		display: 'inline'
	},
	silver: {
		color: '#9a9a9a',
		backgroundColor: '#9a9a9a',
		verticalAlign: 'middle',
		float: 'left',
		filter: 'alpha(opacity = 50)',
		opacity: '.5',
		maxWidth: '12px',
		maxHeight: '12px',
		marginTop: '10px',
		marginRight: '10px',
		display: 'inline'
	},
	bronze: {
		color: '#c45928',
		backgroundColor: '#c45928',
		verticalAlign: 'middle',
		float: 'left',
		filter: 'alpha(opacity = 50)',
		opacity: '.5',
		maxWidth: '12px',
		maxHeight: '12px',
		marginTop: '10px',
		marginRight: '10px',
		display: 'inline'
	},
	rowCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	},
	inline: {
		display: 'inline'
	}
})
export const RankContactFullName = () => {
	const record = useRecordContext()
	const classes = useStyles()
	return (
		record ? (
			<Typography component="span" className={classes.rowCell}>{record && record.Contact && record.Contact.FullName ? `${record.Contact.FullName}` : ''}</Typography>
		) : (
			''
		)
	)
}
export default RankContactFullName