import { useRecordContext } from 'react-admin'
import Download from '@mui/icons-material/Download'
export const PodiumBadge = () => {
	const record = useRecordContext()
	let newName = ''
	newName = record.SubEventName
	if (record.SubEventName.includes('70.3')){
		newName = record.SubEventName
		// newName = newName.replace('IRONMAN 70.3', '')
		// newName = newName.replace(' ', '')
		// newName = '703'+newName
		// newName = newName.replace(/ /g, '')
		if (record.Rank === '1'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '2'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '3'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '4'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '5'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		else if (record.Rank > 5){
			newName = ''
		}
	}
	else {
		newName = record.SubEventName
		// newName = newName.replace('IRONMAN', '')
		// newName = newName.replace(/ /g, '')
		if (record.Rank === '1'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '2'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '3'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '4'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		if (record.Rank === '5'){
			newName = newName + ' TriClub Podium Award_'+record.Rank+'.png'
		}
		else if (record.Rank > 5){
			newName = ''
		}
	}
	let imageLink = ''
	if (newName !== ''){
		imageLink = 'https://assets.competitor.com/badges/podium/' + newName
		console.log(imageLink)
	}
	return (
		record && (newName !== '' || !newName)
			? (
				<a style={{color: '#e31837'}} target="_blank" rel="noopener noreferrer" href={record && newName !== '' ? `${imageLink}` : ''}>{record && newName !== '' ? <Download/> : ''}</a>
			) : ('')
	)
}
export default PodiumBadge
