import React from 'react'
import {
	email,
	required,
	minValue,
	Form,
	FormDataConsumer, maxLength, TextInput, ImageInput, ImageField, CheckboxGroupInput, BooleanInput, SelectInput, SelectField, RadioButtonGroupInput, NumberInput
} from 'react-admin'
import ReCAPTCHA from 'react-google-recaptcha'
import makeStyles from './../makeStyles'
import { theme } from '../../../newTheme'
import { countryChoices } from '../countryChoices'
import { CountryOptionRenderer } from '../countryOptionRenderer'
import image from '../../../assets/img/logos/imclubprogram.png'
import { Box, Button, Step, StepLabel, Stepper, Typography, ButtonGroup, CardMedia, TableContainer, TableBody, TableCell, TableRow, Table, Paper, AlertTitle, Alert } from '@mui/material'
const useStyles = makeStyles({
	website: {
		display: 'inline-block', marginRight: '1rem', textAlign: 'left', verticalAlign: 'middle', padding: '5px', width: '200px'
	},
	hidden: { display: 'none' },
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		textAlign: 'center'
	},
	header: {
		textAlign: 'center',
		margin: '25px !important'
	},
	form: {
		maxWidth: '900px',
		margin: '0 auto',
		padding: '5px',
		textAlign: 'left',
		float: 'none'
	},
	container: {
		textAlign: 'right',
	},
	buttonContainer: {
		textAlign: 'center',
		margin: '10px auto'
	},
	centerTop: {
		textAlign: 'center',
		width: 'auto',
		margin: '0 auto'
	},
	captchaBox: {
		marginBottom: '20px',
		'& div': {
			'& div': {
				'& div': {
					margin: '0 auto',
				}
			}
		}
	},
	centerTopClubCreate: {
		textAlign: 'left',
		width: 'auto',
		margin: '0 auto',
		maxWidth: '500px'
	},
	left: {
		textAlign: 'left'
	},
	accordionUi: {
		marginBottom: '20px',
		maxWidth: '900px',
		display: 'flex',
		justifyContent: 'center'
	},
	cellType: {
		fontWeight: 'bold',
		fontSize: 'large',
		borderRight: '1px solid'
	},
	cellTypeII: {
		color: 'grey',
	},
	media: {
		height: 'auto',
		minHeight: '100px',
		maxWidth: '350px',
		width: 'auto',
		textAlign: 'center',
		margin: '0 auto'
	}
})
function getSteps() {
	return ['Information', 'Location', 'Club']
}
function getStepContent(stepIndex, formProps) {
	switch (stepIndex) {
	case 0:
		return (
			<Box>
				<TextInput source="name" validate={required()} resettable resource="club" variant="outlined" size="normal" label="Club Name" fullWidth autoComplete="off" />
				<TextInput label="Website URL" source="Website" type="url" validate={required()} resettable resource="club" variant="outlined" size="normal" helperText="If website not applicable, please use social media URL" fullWidth autoComplete="off" />
				<TextInput validate={[required(), email('Valid Email Required')]} label="Club Contact Email" source="ClubContactEmail" type="email" resettable resource="club" variant="outlined" size="normal" fullWidth autoComplete="off" />
				<ImageInput source="pictures" label="Club Logo" accept="image/*" multiple={true} resource="club" size="normal" variant="outlined" fullWidth labelMultiple="Upload logo here: 500 x 500 PNG or JPG work best">
					<ImageField source="src" title="title" variant="outlined" size="normal" />
				</ImageInput>
			</Box>
		)
	case 1:
		return (
			<Box>
				<SelectInput source="Country" label="Country" choices={countryChoices} optionText={CountryOptionRenderer} optionValue="name" resettable variant="outlined" size="normal" validate={required()} resource="club" translateChoice={false} fullWidth />
				<FormDataConsumer>
					{({ formData, ...rest }) => formData.Country && formData.Country === 'US' &&
                            <BooleanInput variant="outlined" size="normal" source="isNational" label="Is your Club National?" {...rest} />
					}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, ...rest }) => formData.Country && formData.Country === 'US' &&
                            <TextInput source="StateorProvince" validate={required()} label="State / Province" resettable resource="club" variant="outlined" size="normal" fullWidth />
					}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, ...rest }) => formData.Country && formData.Country !== 'US' &&
                            <TextInput source="StateorProvince" label="State / Province" resettable resource="club" variant="outlined" size="normal" fullWidth />
					}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, ...rest }) => formData.Country &&
                            <TextInput source="PostalCode" label="ZIP / Postal Code" validate={required()} resettable resource="club" variant="outlined" size="normal" fullWidth />
					}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, ...rest }) => formData.Country &&
                            <TextInput source="City" label="City / Town" validate={required()} resettable resource="club" variant="outlined" size="normal" fullWidth />
					}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, ...rest }) => formData.Country &&
                            <TextInput source="Address1" label="Club Mailing Address" validate={required()} resettable resource="club" variant="outlined" size="normal" multiline fullWidth />
					}
				</FormDataConsumer>
			</Box>
		)
	case 2:
		return (
			<Box>
				<NumberInput label="Number of Club Members?" source="members" validate={[required(), minValue(1)]} defaultValue={1} resource="club" variant="outlined" size="normal" fullWidth />
				<RadioButtonGroupInput label="What type of sport does your club specialize in?" defaultValue="957700000" source="ClubType" resource="club" choices={[
					{ id: '957700000', name: 'Tri' },
					// { id: '957700003', name: 'Swim' },
					// { id: '957700001', name: 'Bike' },
					// { id: '957700002', name: 'Run' },
				]} validate={required()} variant="outlined" size="normal" row fullWidth translateChoice={false} />
				<CheckboxGroupInput label="Does your club specialize in a type of athlete?" helperText="Check all that apply" source="ClubSpecialties" choices={[
					{ id: 957700000, name: 'Age Group Athletes' },
					{ id: 957700001, name: 'Elite Athletes' },
					{ id: 957700002, name: 'Women\'s Programs' },
					{ id: 957700003, name: 'First Timer Programs' },
				]} row fullWidth size="normal" variant="outlined" translateChoice={false} />
				<TextInput validate={required()} label="Primary Contact – First Name" source="PrimaryContactFirstName" resettable resource="club" variant="outlined" size="normal" fullWidth />
				<TextInput validate={required()} label="Primary Contact - Last Name" source="PrimaryContactLastName" resettable resource="club" variant="outlined" size="normal" fullWidth />
				<TextInput validate={[required(), email('Valid Email Required')]} label="Primary Contact - Email Address" source="PrimaryContactEmail" type="email" resettable resource="club" variant="outlined" size="normal" fullWidth />
				<TextInput validate={required()} label="Primary Contact - Phone Number" source="PrimaryContactPhone" resettable resource="club" variant="outlined" size="normal" fullWidth />
				<TextInput source="ClubDescription" label="Club Description" resettable size="normal" variant="outlined" helperText="Optional – info to appear on public facing club finder. Max 255 characters" validate={maxLength(255, 'Maximum Length of 255 Characters')} fullWidth multiline />
			</Box>
		)
	default:
		return 'Unknown stepIndex'
	}
}
const validateClubCreation = (values) => {
	const errors = {}
	errors.otherValues = {}
	if (!values.name) {
		errors.name = ['The Club name is required']
	}
	if (!values.Website) {
		errors.Website = ['The Club Website is required']
	}
	if (!values.ClubContactEmail) {
		errors.ClubContactEmail = ['The ClubContactEmail is required']
	}
	if (!values.City) {
		errors.City = ['The Club City is required']
	}
	if (!values.PostalCode) {
		errors.PostalCode = ['The Club PostalCode is required']
	}
	if (!values.Country) {
		errors.Country = ['The Club Country is required']
	}
	if (!values.members) {
		errors.members = ['The members is required']
	}
	if (!values.ClubType) {
		errors.ClubType = ['The ClubType is required']
	}
	if (!values.PrimaryContactFirstName) {
		errors.PrimaryContactFirstName = ['The PrimaryContactFirstName is required']
	}
	if (!values.PrimaryContactLastName) {
		errors.PrimaryContactLastName = ['The PrimaryContactLastName is required']
	}
	if (!values.PrimaryContactEmail) {
		errors.PrimaryContactEmail = ['The PrimaryContactEmail is required']
	}
	if (!values.PrimaryContactPhone) {
		errors.PrimaryContactPhone = ['The PrimaryContactPhone is required']
	}
	return errors
}
export const ClubMultiStepForm = (props) => {
	const classes = useStyles()
	const [activeStep, setActiveStep] = React.useState(0)
	const steps = getSteps()
	const [disableSubmit, setDisableSubmit] = React.useState(true)
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}
	const handleReset = () => {
		setActiveStep(0)
	}
	const setDisableSubmit2 = () => {
		setDisableSubmit(false)
	}
	return (
		<Form validate={validateClubCreation}>
			<CardMedia
				className={classes.media}
				image={image}
				title="IRONMAN TriCLub Program"
				component="img"
			/>
			<Typography className={classes.header} variant="h5">Tell Us About Your Club</Typography>
			<Box>
				{activeStep === steps.length ? (
					<Box>
						<FormDataConsumer>
							{({
								formData, // The whole form data
								scopedFormData, // The data for this item of the ArrayInput
								getSource, // A function to get the valid source inside an ArrayInput
								...rest
							}) =>
								formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
									<Alert variant="outlined" severity="info" className={classes.centerTopClubCreate}>
										<AlertTitle>Please Confirm your Club information</AlertTitle>
												Review and confirm your club information
									</Alert>
								) : (
									<Alert variant="outlined" severity="success" className={classes.centerTopClubCreate}>
										<AlertTitle>Success</AlertTitle>
												For security purposes, please check your email to confirm & approve your Club’s entry into the IRONMAN TriClub program.
									</Alert>
								)
							}
						</FormDataConsumer>
						<Box className={classes.buttonContainer}>
							<TableContainer component={Paper} varient="outlined">
								<Table className={classes.accordionUi} aria-label="simple table" size="small" component="div" padding="default">
									<TableBody>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name ? (
													<TableRow key={formData.name}>
														<TableCell align="left" size="small" >Club Name</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.name} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.Website ? (
													<TableRow key={formData.Website}>
														<TableCell align="left" size="small" >Club Website</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.Website} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.ClubContactEmail}>
														<TableCell align="left" size="small">Club Contact Email</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.ClubContactEmail} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.City}>
														<TableCell align="left" size="small">Club city</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.City} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.PostalCode}>
														<TableCell align="left" size="small" >Club Postal code</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.PostalCode} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.Country}>
														<TableCell align="left">Club Country</TableCell>
														<TableCell align="left" className={classes.cellTypeII}>{formData.Country}</TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.members}>
														<TableCell align="left" size="small">Members</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.members} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone && formData.ClubSpecialties ? (
													<TableRow key={formData.ClubSpecialties}>
														<TableCell align="left" size="small">Specialty</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>
															<SelectField source={formData.ClubSpecialties} optionValue={formData.ClubSpecialties} choices={[
																{ id: '957700000', name: 'Age Group Athletes' },
																{ id: '957700001', name: 'Elite Athletes' },
																{ id: '957700002', name: 'Women\'s Programs' },
																{ id: '957700003', name: 'First Timer Programs' },
															]} fullWidth translateChoice={false} />
														</TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone && formData.ClubType === '957700000' ? (
													<TableRow key={formData.ClubType}>
														<TableCell align="left" size="small">Club Type</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>Tri</TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone && formData.ClubType === '957700003' ? (
													<TableRow key={formData.ClubType}>
														<TableCell align="left" size="small">Club Type</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>Swim</TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone && formData.ClubType === '957700001' ? (
													<TableRow key={formData.ClubType}>
														<TableCell align="left" size="small">Club Type</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>Bike</TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone && formData.ClubType === '957700002' ? (
													<TableRow key={formData.ClubType}>
														<TableCell align="left" size="small">Club Type</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>Run</TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.PrimaryContactFirstName}>
														<TableCell align="left" size="small">Primary Contact</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.PrimaryContactFirstName} {formData.PrimaryContactLastName} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.PrimaryContactEmail}>
														<TableCell align="left" size="small">Primary Contact Email</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.PrimaryContactEmail} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({
												formData, // The whole form data
												scopedFormData, // The data for this item of the ArrayInput
												getSource, // A function to get the valid source inside an ArrayInput
												...rest
											}) =>
												formData && formData.name && formData.Website && formData.ClubContactEmail && formData.City && formData.PostalCode && formData.Country && formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
													<TableRow key={formData.PrimaryContactPhone}>
														<TableCell align="left" size="small">Primary Contact Phone</TableCell>
														<TableCell align="left" size="small" className={classes.cellTypeII}>{formData.PrimaryContactPhone} </TableCell>
													</TableRow>
												) : null
											}
										</FormDataConsumer>
									</TableBody>
								</Table>
							</TableContainer>
							<Box className={classes.captchaBox}>
								<ReCAPTCHA
									sitekey='6Lc8_GUUAAAAAIF0ecNRakqISKuspsmpbMEA7UuY'
									onChange={setDisableSubmit2}
								/>
							</Box>
							<ButtonGroup >
								<Button type="submit" variant="contained" disabled={disableSubmit} color="primary">
											Submit
								</Button>
							</ButtonGroup>
							<Button onClick={handleReset}>Back</Button>
						</Box>
					</Box>
				) : (
					<Box>
						<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
						<Box className={classes.container}>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.backButton}
							>
                                            Back
							</Button>
							<FormDataConsumer>
								{({
									formData, // The whole form data
									scopedFormData, // The data for this item of the ArrayInput
									getSource, // A function to get the valid source inside an ArrayInput
									...rest
								}) =>
									formData && activeStep === 0 && !formData.name && !formData.Website && !formData.ClubContactEmail ? (
										<Button variant="contained" color="primary" onClick={handleNext} disabled={true}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									) : formData && activeStep === 0 && formData.name && formData.Website && formData.ClubContactEmail ? (
										<Button variant="contained" color="primary" onClick={handleNext}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									) : formData && activeStep === 1 && !formData.City && !formData.PostalCode && !formData.Country ? (
										<Button variant="contained" color="primary" onClick={handleNext} disabled={true}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									) : formData && activeStep === 1 && formData.City && formData.PostalCode && formData.Country ? (
										<Button variant="contained" color="primary" onClick={handleNext}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									) : formData && activeStep === 2 && !formData.members && !formData.ClubType && !formData.PrimaryContactFirstName && !formData.PrimaryContactLastName && !formData.PrimaryContactEmail && !formData.PrimaryContactPhone ? (
										<Button variant="contained" color="primary" onClick={handleNext} disabled={true}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									) : formData.members && formData.ClubType && formData.PrimaryContactFirstName && formData.PrimaryContactLastName && formData.PrimaryContactEmail && formData.PrimaryContactPhone ? (
										<Button variant="contained" color="primary" onClick={handleNext}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									) : (
										<Button variant="contained" color="primary" onClick={handleNext} disabled={true}>
											{activeStep === steps.length - 1 ? 'Next' : 'Next'}
										</Button>
									)
								}
							</FormDataConsumer>
						</Box>
					</Box>
				)}
			</Box>
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Form>
	)
}
export default ClubMultiStepForm