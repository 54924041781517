import { useFilterStyles } from '../filterStyling'
import { Choices } from '../dynamicAgeGroupChoices'
import { countryChoices } from '../countryChoices'
import { CountryOptionRenderer } from '../countryOptionRenderer'
import { Filter, TextInput, SelectInput} from 'react-admin'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
export const ResultsFilter = () => {
	const filterClasses = useFilterStyles()
	if (Choices){
		return (
			<Filter className={filterClasses.filterOneLine}>
				<TextInput label="Search" source="$search" alwaysOn resettable size="medium" variant="outlined" InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon color="disabled" />
						</InputAdornment>
					)
				}} />
				<SelectInput source="AgeGroup" label="Category" choices={Choices} translateChoice={false} alwaysOn resettable size="medium" variant="outlined"  />
				<SelectInput source="CountryRepresentingISONumeric" label="Country" choices={countryChoices} translateChoice={false} optionText={CountryOptionRenderer} optionValue={'id'} alwaysOn resettable size="medium" variant="outlined" />
			</Filter>
		)
	}
}
export default ResultsFilter