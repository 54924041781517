import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from './components/makeStyles'
import {List, TextField, Datagrid, DateField, ChipField, Pagination} from 'react-admin'
import EventsResultsLink2 from './components/events/eventsResultsLink2'
import EventsPodiumAwardsLink from './components/events/eventsPodiumAwardsLink'
import EventsShow from './components/events/eventsShow'
import SeCodeCopy from './components/events/seCodeCopy'
import SeCodeCopyOpenDiv from './components/events/seCodeCopyOpenDiv'
import SeCodeCopyPodium from './components/events/seCodeCopyPodium'
import EventsResultsLinkOpenDiv from './components/events/eventsResultsLinkOpenDiv'
import EventsFilter from './components/events/eventFilter'
import moment from './components/moment'
const EventsPagination = props => <Pagination rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200, 500, 1000]} {...props} />
const useStyles = makeStyles({
	headerCell: {
		textAlign: 'left'
	},
	rowCell: {
		textAlign: 'left',
		textTransform: 'capitalize',
		'&:hover': {
			color: '#e31837'
		}
	}
})
export const EventsList = () => {
	const today = moment().format('YYYY-MM-DD')
	const classes = useStyles()
	const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
	const isMed = useMediaQuery(theme => theme.breakpoints.down('md'))
	return (
		<List filters={<EventsFilter />} filterDefaultValues={{ SubEventType: 'Race', 'Date[$lte]': today, 'Alias[$ne]': null }} sort={{ field: 'Date', order: 'DESC' }} title=" " pagination={<EventsPagination />} exporter={false} emptyWhileLoading disableAuthentication>
			{isSmall ? (
				<Datagrid optimized expand={<EventsShow />} bulkActionButtons={false} expandSingle rowClick="expand">
					<TextField source="SubEvent" label="SubEvent" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<ChipField source="Brand" label="Brand" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<TextField source="Series" label="Series" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<DateField source="Date" label="Date" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="BusinessUnit" label="Region" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="City" label="City" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="Sport" label="Sport" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
				</Datagrid>
			) : isMed ? (
				<Datagrid optimized expand={<EventsShow />} bulkActionButtons={false} expandSingle rowClick="expand">
					<TextField source="SubEvent" label="SubEvent" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<ChipField source="Brand" label="Brand" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="Series" label="Series" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<DateField source="Date" label="Date" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="BusinessUnit" label="Region" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<TextField source="City" label="City" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="Sport" label="Sport" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<SeCodeCopy label="SE Code Results" />
					<SeCodeCopyPodium label="SE Code Podium Awards" />
					<SeCodeCopyOpenDiv label="SE Code Open Div" />
				</Datagrid>
			) : (
				<Datagrid expand={<EventsShow />} optimized bulkActionButtons={false} expandSingle rowClick="expand">
					<TextField source="SubEvent" label="SubEvent" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<EventsResultsLink2 label="Results Link" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<EventsResultsLinkOpenDiv label="Results Open Division" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<EventsPodiumAwardsLink label="Podium Awards Link" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<ChipField source="Brand" label="Brand" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="Series" label="Series" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<DateField source="Date" label="Date" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<TextField source="Alias" label="Alias" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<TextField source="BusinessUnit" label="Region" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="City" label="City" cellClassName={classes.rowCell} headerClassName={classes.headerCell}/>
					<TextField source="Sport" label="Sport" cellClassName={classes.rowCell} headerClassName={classes.headerCell} />
					<SeCodeCopy label="SE Code Results" />
					<SeCodeCopyPodium label="SE Code Podium Awards" />
					<SeCodeCopyOpenDiv label="SE Code Open Div"/>
				</Datagrid>
			)}
		</List>
	)
}
export default EventsList
