import { Component } from 'react'
import '../../styles/cardShow.css'
import LoadingButton from '@mui/lab/LoadingButton'
import moment from './../moment'
import { options, host, instance } from '../../../feathersClient'
import {Box} from '@mui/material'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
const getAGRCert2 = async(e, props,setLoading) => {
	setLoading(true)
	let contactid
	if (props.Contact && props.Contact.ContactID){
		contactid = props.Contact.ContactID
	}else{
		contactid = props.ContactId
	}
	const points = props.Points
	const brand = props.Brand
	const year = props.Year
	const status = props.AWAStatus
	const createCertURL = host + '/contact/'+ contactid +'/points/'+ points +'/brand/'+ brand + '/year/' + year + '/status/' + status + '/cert/true'
	const getagrCertURL = 'https://feathersprod.blob.core.windows.net/api-public/' + contactid + '.pdf'
	return instance.get(createCertURL, options)
		.then((resp)=>resp).then( async (respJSON)=>{
			if(respJSON){
				console.log('Generating Cert...')
				const response = await instance.get(createCertURL, options)
				console.log(response)
				let rankResults = response.data[0]
				if(rankResults.length !== 0 && response.status===200){
					let certRes = ''
					do {
					  certRes = await fetch(getagrCertURL, options)
					}
					while (certRes.status !== 200)
					setLoading(false)
					window.open(getagrCertURL, '_blank')
				}
			}
		}
		)
}
class CertButton extends Component{
	constructor(props) {
		super(props)
		this.state = {
			awastatus: props.AWAStatus,
			year: props.Year,
			display: false,
			imageLink: null,
			loading: false,
		}
	}
	render() {
		const {loading,setLoading} = this.props
		const currentYear = moment().year()
		console.log(this.state)
		console.log(loading)
		return (
			this.props && this.props.AWAStatus && this.props.Year !== currentYear ? (
				<Box>
					{/* <Box className={'awaCertDiv'}>
						<Button sx={{maxHeight:25}} className="certButton" onClick={(e) => getAGRCert2(e,this.props)} target="_blank">AWA Certificate</Button>
						<PictureAsPdfOutlinedIcon className="pdfIcon" />
					</Box> */}
					<LoadingButton loading={loading} variant="outlined" startIcon={<PictureAsPdfOutlinedIcon />} onClick={(e) => getAGRCert2(e,this.props,setLoading)} target="_blank">AWA Certificate</LoadingButton>
				</Box>
			) : null
		)
	}
}
export default CertButton
