import { useState } from 'react'
import { Notification } from 'react-admin'
const MyNotification = props => {
	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	})
	const { vertical, horizontal, open } = state
	return (
		<Notification {...props} autoHideDuration={10000} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} />
	)
}
export default MyNotification
